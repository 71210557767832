import React, { useContext, useEffect, useState } from "react";
import { PaymentlinkContext } from "../../app/Core/PaymentLinkContext";
import { formatAmount } from "../../app/services/functionServices";
import {
  getTokenContractAddress,
  networksAmountMap,
  networksAmountNameMap,
  networkschainIdMap,
  networksNameMap,
} from "../../app/services/maps";
import { CircleProgressBar } from "../progressBar/CircleProgressBar";
import { QrDataWalletSuggest } from "../QrDataWalletSuggest";
import { TextCopyWidget } from "../TextCopyWidget";
import { TimeCounter } from "../TimeCounter";

interface props {
  setActiveIndex: (activeIndex: number) => void;
}

export const PaymentlinkStepTwo = ({ setActiveIndex }: props) => {
  const [qrLoading, setQrLoading] = useState(true);
  const { price, priceCurrency, coinsListMap, paymentlinkSession } =
    useContext(PaymentlinkContext);

  useEffect(() => {
    setQrLoading(true);
  }, [paymentlinkSession?.wallet]);

  useEffect(() => {
    Number(paymentlinkSession?.step) === 3 && setActiveIndex(2);
    // eslint-disable-next-line
  }, [paymentlinkSession?.step]);

  return (
    <>
      {paymentlinkSession && (
        <>
          <div className="p-10">
            <div>
              <h4 className="fs-5 fw-semibold text-gray-800 mb-1">
                Amount to pay
              </h4>
              <TextCopyWidget
                text={formatAmount(
                  parseFloat(paymentlinkSession.amount),
                  coinsListMap[paymentlinkSession.coin]?.displayPrecision
                )}
                addonText={paymentlinkSession.coin}
                tooltipText="Copy Amount"
                className="fs-5 mb-0 mt-7 fm-jetbrain"
              />
              <span className="text-gray-700 fw-semibold ms-1 fm-jetbrain">
                ({priceCurrency === "USD" ? "$" : ""}
                {Number(price).toLocaleString()}
                {priceCurrency !== "USD" ? " " + priceCurrency : ""})
              </span>
              <h4 className="bg-light-warning rounded px-2 d-inline-block py-1 fs-5 fw-semibold text-warning mt-0 mb-1">
                Sending less may result in fund loss.
              </h4>

              <h4 className="fs-5 fw-semibold text-gray-800 mt-4">
                Send{" "}
                <span className="text-primary fw-bold">
                  {coinsListMap[paymentlinkSession?.coin]?.name}
                </span>{" "}
                {!coinsListMap[paymentlinkSession.coin]?.networkList[
                  paymentlinkSession.network
                ]?.isMain && (
                  <span className="badge badge-light-primary text-primary">
                    {paymentlinkSession.network}
                  </span>
                )}{" "}
                to this address
              </h4>
              <TextCopyWidget
                text={paymentlinkSession?.wallet}
                tooltipText="Copy Address"
                className="fs-5 mb-2 mt-7 fm-jetbrain"
                qrCode={true}
              />
            </div>
            <div className="bg-light-primary rounded d-flex flex-column flex-md-row justify-content-center align-items-center mt-10 mb-8 gap-3 p-3">
              <div>
                {qrLoading && (
                  <div className="p-2 h-150px w-150px bg-secondary rounded shine"></div>
                )}
                {paymentlinkSession?.wallet && (
                  <div
                    className={`bg-white p-2 rounded d-block ${
                      qrLoading && "d-none"
                    }`}
                  >
                    <img
                      src={`http://api.qrserver.com/v1/create-qr-code/?data=${
                        networksNameMap[paymentlinkSession.network]
                      }${paymentlinkSession?.wallet}${
                        networkschainIdMap[paymentlinkSession.network]
                      }${networksAmountNameMap[paymentlinkSession.network]}${
                        paymentlinkSession.network === ("Ton" || "Polygon")
                          ? Math.floor(
                              Number(paymentlinkSession.amount) *
                                networksAmountMap[paymentlinkSession.network]
                            )
                          : Number(paymentlinkSession.amount) *
                            networksAmountMap[paymentlinkSession.network]
                      }${getTokenContractAddress(
                        paymentlinkSession.coin,
                        paymentlinkSession.network
                      )}&size=150x150`}
                      alt="QR code"
                      style={{ width: "150px" }}
                      onLoad={() => {
                        setQrLoading(false);
                      }}
                    />
                  </div>
                )}
              </div>
              <QrDataWalletSuggest />
            </div>

            <div className="d-flex align-items-center mt-3">
              <CircleProgressBar
                duration={Number(paymentlinkSession.duration)}
                endtime={paymentlinkSession.expireAt}
              />
              <p className="ms-2 my-0">
                The rate will be updated in{" "}
                <span className="text-primary fw-bold">
                  <TimeCounter endtime={paymentlinkSession.expireAt} />
                </span>
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};
