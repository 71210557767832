export const merchantsSection = [
  {
    q: "Create invoice",
    a: `Imagine you're an online store owner whose customer wants to pay using cryptocurrency. OxaPay's Merchant API simplifies the process with its "Create Invoice" feature. You can create an invoice with the API and get a link in response. This link is a direct pathway for your customers, guiding them seamlessly through the payment process via a user-friendly URL. It's a hassle-free solution ensuring swift and secure payments, enhancing customers' shopping experience. Managing online transactions has never been easier.`,
  },
  {
    q: "White-label Payments",
    a: "With OxaPay's white-label payments feature, you can offer your customers a seamless payment experience, all while leveraging the power of the OxaPay payment gateway behind the scenes. This unique feature goes beyond generating invoices; it provides comprehensive payment details, including payment address, currency, amount, and expiration time, ensuring transparency and clarity for both you and your customers. Imagine having the flexibility to customize payment processes under your brand's identity, enhancing trust and recognition.",
  },
  {
    q: "Static Address",
    a: "You can create a static address in a specific currency and network using this feature. The static address will be associated with a unique trackId, and if a callbackUrl is set, your server will be notified of any payments received to that address. This allows you to receive transactions to the static address regardless of the amount and time.",
  },
];

export const coinPublicQuestions = (coinName: string) => [
  {
    q: `How can I store ${coinName}?`,
    a: `Storing ${coinName} is streamlined with a variety of wallet options at your disposal, including desktop wallets, web wallets, mobile wallets, and hardware wallets. OxaPay offers a secure wallet solution for storing ${coinName} directly on its platform, and users can also utilize the web application wallet within the Telegram platform to securely manage their ${coinName} holdings. This provides users with a straightforward way to create and oversee their ${coinName} assets with peace of mind.`,
  },
  {
    q: `Why should I accept ${coinName} payments?`,
    a: `Accepting ${coinName} payments can attract a broader customer base, simplify international transactions, and offer a stable payment method.`,
  },
  {
    q: `Are ${coinName} payments secure?`,
    a: `Yes, ${coinName} payments are secure as they are based on blockchain technology. OxaPay adds an extra layer of security to ensure safe transactions.`,
  },
  {
    q: `What are the benefits of accepting ${coinName} payments?`,
    a: `Accepting ${coinName} payments provides your business with a stable and secure payment method, broader customer reach, and simplified cross-border transactions. OxaPay further ensures a smooth payment experience.`,
  },
  {
    q: `How to accept ${coinName} payments?`,
    a: `To accept ${coinName} payments, you can utilize the OxaPay payment gateway. Begin by registering with OxaPay, choose from a variety of OxaPay services tailored to your business's specific needs, and seamlessly integrate ${coinName} payments into your operations.`,
  },
  {
    q: `What is a ${coinName} payment gateway?`,
    a: `A ${coinName} payment gateway serves as a vital link between customers and businesses, enabling businesses to readily embrace ${coinName} payments. OxaPay offers a trusted and secure gateway for businesses seeking to accept ${coinName} payments.`,
  },
  {
    q: `How to set up a ${coinName} payment gateway?`,
    a: `Set up a ${coinName} payment gateway with OxaPay by creating an account, customizing your gateway settings, and integrating it into your website or platform.`,
  },
  {
    q: `How do I accept ${coinName} donations?`,
    a: `Accepting ${coinName} donations through OxaPay is a straightforward process. Within your OxaPay account panel, you can choose the 'Donation' option. From there, you can generate a personalized payment link or QR code, selecting ${coinName} as the preferred cryptocurrency for donations. Share this link or code with potential donors.`,
  },
  {
    q: `What are the fees for accepting ${coinName} payments with OxaPay?`,
    a: `OxaPay offers competitive and transparent <a href="https://oxapay.com/pricing" target="_blank" rel="noreferrer">pricing<a/> for accepting ${coinName} payments. For detailed fee information, you can visit our pricing page on the OxaPay website.`,
  },
  {
    q: `What is the ${coinName} withdrawal fee at OxaPay?`,
    a: `${coinName} withdrawal costs can vary based on the fees in different networks. For detailed information, please visit the <a href="https://oxapay.com/pricing" target="_blank" rel="noreferrer">pricing<a/> page on our website.`,
  },
  {
    q: `Are there any OxaPay training resources for accepting ${coinName}?`,
    a: `Yes, OxaPay offers comprehensive training and support resources to assist businesses in making the most of their ${coinName} payment solutions. These resources include <a href="https://oxapay.com/blog" target="_blank" rel="noreferrer">blog<a/> and <a href="https://docs.oxapay.com/" target="_blank" rel="noreferrer">documents<a/> designed to help you navigate the process effectively. Additionally, you can find answers to frequently asked questions on the OxaPay customer support page.`,
  },
];

export const coinPrivateQuestions = (coinName: string) => {
  switch (coinName.toLowerCase()) {
    case "tether":
      return [
        {
          q: "What is Tether (USDT)?",
          a: "USDT, or Tether, is a stablecoin in the world of cryptocurrencies. It's designed to maintain a stable value by being backed by real-world assets, such as the US dollar.",
        },
        {
          q: "Tehter vs. Bitcoin",
          a: "Tehter and Bitcoin are distinct digital currencies. Bitcoin is known for its volatility and is often used as a long-term investment and store of value. In contrast, USDT is specifically designed to maintain a stable value by being pegged to the US dollar. This stability makes USDT a preferred choice for transactions and everyday payments. The choice between them depends on your financial goals and how you intend to use them.",
        },
        {
          q: "What networks does OxaPay support for USDT?",
          a: "OxaPay supports Ethereum (ERC-20), Tron (TRC-20), Polygon, Ton and Smartchain (BEP-20) networks for USDT transactions.",
        },
      ];
    case "monero":
      return [
        {
          q: "What is Monero (XMR)?",
          a: "Monero is a privacy-focused cryptocurrency that emphasizes anonymity. It operates similarly to Bitcoin but employs advanced cryptographic techniques to hide transaction details, making it highly confidential and secure. Monero aims to provide users with untraceable digital transactions.",
        },
        {
          q: "Monero vs. Bitcoin",
          a: "Monero and Bitcoin are distinct cryptocurrencies. While Bitcoin is known for its transparency and digital gold status, Monero stands out with its focus on privacy and confidentiality. Monero transactions are untraceable, making it a preferred choice for users who prioritize anonymity in their financial activities.",
        },
      ];
    case "bitcoin":
      return [
        {
          q: "What is Bitcoin (BTC)",
          a: "Bitcoin is a decentralized digital currency that enables peer-to-peer transactions, using a technology called blockchain. It allows secure, borderless, and efficient transfers of value.",
        },
        {
          q: "How does Bitcoin work?",
          a: "Bitcoin transactions are verified and recorded on the blockchain through a process called mining, where powerful computers solve complex mathematical puzzles. This technology ensures the integrity and security of Bitcoin transactions.",
        },
      ];
    case "litecoin":
      return [
        {
          q: "What is Litecoin (LTC)?",
          a: `Litecoin is a peer-to-peer cryptocurrency created by Charlie Lee, offering fast and secure transactions. Often referred to as the "silver" to Bitcoin's "gold," Litecoin shares similarities with Bitcoin in its decentralized and blockchain-based nature.`,
        },
        {
          q: "Litecoin vs. Bitcoin",
          a: "Litecoin and Bitcoin are both decentralized digital currencies, but Litecoin has faster block generation times (2.5 minutes compared to Bitcoin's 10 minutes) and uses the Scrypt hashing algorithm. Bitcoin has a larger market capitalization and a maximum supply of 21 million coins, while Litecoin has 84 million.",
        },
      ];
    case "dogecoin":
      return [
        {
          q: "What is Dogecoin (DOGE)?",
          a: `Dogecoin is a peer-to-peer cryptocurrency inspired by the "Doge" Internet meme, featuring the Shiba Inu dog. It's designed for fast and fun transactions and has gained popularity for its active online community.`,
        },
        {
          q: "Dogecoin vs. Bitcoin",
          a: "Dogecoin and Bitcoin are both cryptocurrencies, but Dogecoin offers faster block generation (1 minute compared to Bitcoin's 10 minutes) and uses a different mining algorithm. Dogecoin has a larger maximum supply of 130 billion coins, compared to Bitcoin's 21 million.",
        },
      ];
    case "usd-coin":
      return [
        {
          q: "What is USD coin (USDC)?",
          a: "USDC is a stablecoin, a type of cryptocurrency that is pegged to a stable asset, in this case, the U.S. Dollar. It's designed to maintain a 1:1 value ratio with the USD, providing stability in the volatile world of cryptocurrencies.",
        },
        {
          q: "USD Coin vs. Bitcoin",
          a: "USDC is fundamentally different from Bitcoin. While Bitcoin is a decentralized digital currency, USDC is a stablecoin, a type of cryptocurrency that aims to maintain a stable value and is often used for transactions and as a store of value.",
        },
      ];
    case "binance-usd":
      return [
        {
          q: "What is Binance USD (BUSD)?",
          a: "Binance USD (BUSD) is a stablecoin, a cryptocurrency that is pegged to the U.S. Dollar and issued by Binance, one of the world's largest cryptocurrency exchanges. BUSD aims to maintain a 1:1 value ratio with the USD, providing stability in the crypto market.",
        },
        {
          q: "Binance USD vs. Bitcoin",
          a: "BUSD and Bitcoin serve different purposes. Bitcoin is a decentralized digital currency and store of value, while BUSD is a stablecoin designed to maintain a consistent value equivalent to the U.S. Dollar.",
        },
      ];
    case "bnb":
      return [
        {
          q: "What is Binance coin (BNB)?",
          a: "Binance Coin (BNB) is the native cryptocurrency of the Binance cryptocurrency exchange, one of the largest and most popular exchanges in the world. BNB serves various functions within the Binance ecosystem, including trading fee discounts, staking, participating in token sales on Binance Launchpad, and more.",
        },
        {
          q: "Binance coin vs. Bitcoin",
          a: "BNB and Bitcoin are different types of cryptocurrencies. Bitcoin is a decentralized digital currency and store of value, while BNB is a utility token associated with the Binance exchange and its ecosystem.",
        },
      ];
    case "digibyte":
      return [
        {
          q: "What is Digibyte (DGB)?",
          a: "DigiByte (DGB) is a decentralized blockchain and cryptocurrency that focuses on enhanced security, fast transaction speeds, and decentralized applications. It was created to improve upon the perceived shortcomings of Bitcoin.",
        },
        {
          q: "Digibyte vs. Bitcoin",
          a: "DGB and Bitcoin share similarities, such as being decentralized cryptocurrencies, but they differ in their underlying technology and focus. DigiByte is designed for faster transactions and enhanced security, while Bitcoin is the pioneering cryptocurrency and store of value.",
        },
      ];
    case "ethereum":
      return [
        {
          q: "What is Ethereum (ETH)?",
          a: "Ethereum is an open-source blockchain platform that enables developers to build decentralized applications (DApps) and smart contracts. Ether (ETH) is the native cryptocurrency of the Ethereum network.",
        },
        {
          q: "Ethereum vs. Bitcoin",
          a: "Ethereum and Bitcoin are both decentralized cryptocurrencies, but they have distinct purposes. While Bitcoin primarily serves as a digital store of value, Ethereum focuses on enabling programmable smart contracts and DApps.",
        },
      ];
    case "ton":
      return [
        {
          q: "What is Toncoin (TON)?",
          a: "Toncoin (TON) is a layer-1 blockchain platform that was developed by Telegram. It is designed to be a fast, scalable, and secure platform for decentralized applications (dApps) and financial services. TON has its own native cryptocurrency, also called Toncoin, which can be used to pay for transaction fees on the network and to access various dApps and services.",
        },
        {
          q: "Toncoin vs. Bitcoin",
          a: "Both Toncoin and Bitcoin are decentralized cryptocurrencies, but there are some key differences between the two. Bitcoin is the oldest and most well-known cryptocurrency, but it has some limitations, such as its slow transaction speed and high fees. Toncoin, on the other hand, is a newer cryptocurrency that has been designed to address these limitations. Toncoin transactions are much faster and cheaper than Bitcoin transactions, and the network is more scalable.",
        },
      ];

    case "bitcoin-cash":
      return [
        {
          q: "What is Bitcoin Cash (BCH)?",
          a: "Bitcoin Cash (BCH) is a cryptocurrency that was created from a hard fork of Bitcoin in August 2017. It was developed to address some of the scalability issues of Bitcoin by increasing the block size. This change allows for more transactions to be processed per block, leading to faster transaction times and lower fees compared to Bitcoin. Bitcoin Cash operates on a decentralized network and uses a proof-of-work consensus mechanism similar to Bitcoin.",
        },
        {
          q: "Bitcoin Cash vs. Bitcoin",
          a: `The main difference between Bitcoin Cash (BCH) and Bitcoin (BTC) lies in their approach to scaling and transaction capacity. Bitcoin Cash has a larger block size limit, which allows for more transactions per block, thus potentially offering faster processing times and lower transaction fees. In contrast, Bitcoin maintains a smaller block size and is exploring off-chain solutions like the Lightning Network for scalability. Both use a proof-of-work consensus mechanism, but they have different focuses: Bitcoin is widely regarded as a store of value or "digital gold," while Bitcoin Cash is designed to be more efficient for everyday transactions, aiming to be a usable digital cash system.`,
        },
      ];
    case "tron":
      return [
        {
          q: "What is Tron (TRX)?",
          a: "Tron (TRX) is a blockchain-based decentralized platform designed to build and deploy smart contracts and decentralized applications (DApps). It aims to create a decentralized internet, enhancing content sharing and empowering creators.",
        },
        {
          q: "Tron vs. Bitcoin",
          a: "While both Tron and Bitcoin are cryptocurrencies, they serve different purposes. Bitcoin primarily acts as digital gold and a store of value, while Tron focuses on enabling the creation of decentralized applications and smart contracts.",
        },
      ];
    case "polygon":
      return [
        {
          q: "What is Polygon (MATIC)?",
          a: "Polygon is a blockchain scaling platform that enables developers to build Ethereum-compatible decentralized applications (dApps) with faster transaction speeds and lower fees. It is a layer-2 solution that runs on top of the Ethereum mainchain, which means it does not compete with Ethereum but rather complements it. Polygon achieves its scalability by using a technique called sidechains. This allows transactions to be processed off-chain, reducing congestion on the mainchain and improving transaction speeds.",
        },
      ];
    case "shiba-inu":
      return [
        {
          q: "What is Shiba Inu (SHIB)?",
          a: `Shiba Inu (SHIB) is a decentralized meme cryptocurrency created in August 2020, named after the Shiba Inu dog breed. Known as a "meme coin" and dubbed the "Dogecoin killer," it was initially created on the Ethereum blockchain but has now expanded to operate on other blockchains, including the Smart Chain. This allows it to be used in decentralized finance (DeFi) applications across multiple ecosystems. SHIB was initially issued with a one quadrillion token supply, with significant portions locked for liquidity and partially burned. Its popularity is largely driven by its vibrant online community and social media, leading to high market volatility.`,
        },
        {
          q: "Shiba Inu vs. Bitcoin",
          a: `Shiba Inu (SHIB) and Bitcoin (BTC) differ greatly in purpose and design. Bitcoin, launched in 2009, aims to be a decentralized digital currency and store of value with a limited supply of 21 million coins, focusing on security and scarcity. Shiba Inu, introduced in 2020, is a meme cryptocurrency built on the Ethereum blockchain, emphasizing community engagement with a supply of one quadrillion tokens, some of which were burned. While Bitcoin is considered "digital gold," aiming for financial stability, Shiba Inu is driven by social media and community, leading to speculative investment and higher volatility.`,
        },
        {
          q: "Shiba Inu vs. Dogecoin",
          a: `Shiba Inu (SHIB) and Dogecoin (DOGE) are both meme cryptocurrencies featuring the Shiba Inu dog. Dogecoin, created in 2013, focuses on being a fun, transactional currency with an unlimited supply, promoting its use in tipping and donations. Shiba Inu, launched in 2020 as the "Dogecoin killer," operates on the Ethereum blockchain, enabling DeFi applications and smart contracts, with a fixed supply of one quadrillion tokens, some of which have been burned. While Dogecoin aims for simplicity and wide usage in transactions, Shiba Inu seeks to provide utility in the DeFi space, backed by a strong community.`,
        },
      ];
    case "notcoin":
      return [
        {
          q: "What is Notcoin?",
          a: `Notcoin (NOT) is a groundbreaking cryptocurrency that merges gaming and blockchain technology, offering a distinctive "tap to earn" mechanism. Originally a straightforward game on Telegram, players could earn Notcoins by simply tapping their screen, which rapidly propelled the game to viral status, attracting up to 35 million users. This innovative approach made digital currency accessible to a broad audience without requiring any initial investment.`,
        },
        {
          q: 'How does the "tap to earn" system work in the Notcoin game?',
          a: `The "tap to earn" game lets players tap on their screen to complete tasks or reach goals. In the Notcoin game, players earn Notcoins by tapping. This simple and fun method appeals to many because it's easy to play. Each tap helps players earn cryptocurrency, blending the excitement of games with the benefits of digital money.`,
        },
      ];
    case "solana":
      return [
        {
          q: "What is Solana (SOL)?",
          a: "Solana is an advanced open-source blockchain platform known for its high-performance capabilities, allowing for fast and scalable decentralized applications (DApps) and smart contracts. Solana's architecture is designed to support high transaction speeds and low transaction costs, making it an attractive platform for a wide range of decentralized finance (DeFi) applications, gaming, and more. SOL is the native cryptocurrency of the Solana network, used for transaction fees and staking to secure the network.",
        },
        {
          q: "Solana vs. Ethereum",
          a: `Solana and Ethereum are both blockchain platforms designed for building decentralized applications (DApps) and smart contracts. The key differences lie in their underlying technologies and performance metrics. Ethereum, being the older platform, has a wide developer base and ecosystem, offering robust security and decentralization. However, it has faced scalability issues, leading to higher transaction fees and slower speeds. Solana, on the other hand, employs a unique consensus mechanism combining Proof of Stake (PoS) with Proof of History (PoH), enabling much higher transaction speeds and lower costs, but faces criticisms regarding centralization and network stability.`,
        },
      ];
    default:
      // Return an empty array or handle unknown coin names as needed
      return [];
  }
};

export const paymentLinkQuestions = [
  {
    q: "What is Payment Link?",
    a: "Payment Links are a convenient way to request cryptocurrency payments from customers. They are generated through OxaPay and can be shared via email, messaging apps, or embedded on your website. When a customer clicks the link, it directs them to a payment page where they can complete the transaction using various cryptocurrencies.",
  },
  {
    q: "How do I create a payment link?",
    a: `1. Sign up for an OxaPay account.<br/>2. Access the "Payment Link" feature.<br/>3. Customize the link by setting the payment amount, currency, and optional description.<br/>4. Generate the payment link.<br/>5. Share the link with your customers via email, messaging apps, or your website.<br/>6. Customers can click the link and complete the payment.<br/>7. You can track payments through your OxaPay account.<br/><br/>Refer to OxaPay's official <a href="https://docs.oxapay.com/integrations/payment-links" target="_blank" rel="noreferrer">documentation<a/> for comprehensive instructions.`,
  },
  {
    q: "What are the benefits of using a payment link?",
    a: "Payment Links simplify cryptocurrency transactions, allowing you to request payments from customers without the need for complex integrations and no coding. They are versatile, secure, and can be used in various scenarios.",
  },
  {
    q: "Is payment link safe?",
    a: "Yes, Payment Links provided by OxaPay are secure. They are designed to facilitate safe cryptocurrency transactions. However, standard security precautions should be taken to protect your OxaPay account and the links you create.",
  },
  {
    q: "How to use a payment link to receive payments?",
    a: "Simply share the Payment Link with your customer via your preferred communication channel. The customer clicks the link, selects their preferred cryptocurrency, and completes the payment.",
  },
  {
    q: "Do I need a website to use the payment link?",
    a: "No, you don't need a website to use Payment Links. They can be shared through messaging apps, email, or any platform where you can communicate with your customers.",
  },
  {
    q: "What are the fees for using a payment link?",
    a: "OxaPay offers competitive and transparent pricing for payment links. For detailed fee information, you can visit our pricing page on the OxaPay website.",
  },
  {
    q: "Which cryptocurrencies can I accept through payment links with OxaPay?",
    a: "OxaPay offers support for various cryptocurrencies in its payment links, including Bitcoin, Ethereum, Litecoin, Monero, USDT, and more. You can find the complete list of supported digital currencies on the OxaPay website.",
  },
  {
    q: "What is the purpose of the 'Underpaid Cover' feature, and how does it work?",
    a: "The 'Underpaid Cover' feature in OxaPay allows you to set a threshold for payments below 60%, ensuring smooth payment processing, especially for small discrepancies. It's designed to streamline the payment process and minimize disruptions for both invoices and payment link transactions.",
  },
  {
    q: "What happens if I enable the 'Fee paid by payer' option for a Payment Link?",
    a: "Enabling the 'Fee paid by payer' option for a Payment Link in OxaPay allows you to decide who covers the transaction fees. This feature provides you with greater control over your financial decisions and fee management, enhancing transparency in your payment process.",
  },
  {
    q: "How can I track payments made through payment links?",
    a: "You can track payments made through Payment Links in your OxaPay account. Detailed transaction histories and payment statuses are available to help you keep organized records.",
  },
  {
    q: "Can I add a description or additional information to a Payment Link?",
    a: "Yes, you can include a description and other relevant details when creating a Payment Link within the OxaPay platform. This helps provide context and instructions for the payment, making the process more convenient for both you and your customers.",
  },
  {
    q: "Can I edit or modify a created Payment Link?",
    a: "In most cases, Payment Links are immutable for security reasons. To make changes, you typically need to create a new link with the desired modifications.",
  },
];

export const telegramChannelGatewayQuestions = [
  {
    q: "What is a Telegram Channel?",
    a: "A Telegram Channel is a feature on the Telegram messaging platform where channel owners can share information, updates, and content with subscribers.",
  },
  {
    q: "Can I monetize my Telegram Channel?",
    a: "Yes, you can monetize your Telegram Channel by setting up paid membership plans or accepting crypto payments for exclusive content or services.",
  },
  {
    q: "How to accept crypto payments for Telegram channels?",
    a: "OxaPay's Telegram Channel Gateway allows you to accept various cryptocurrencies for your Telegram channel, simplifying the payment process.",
  },
  {
    q: "What is the Telegram Channel Gateway by OxaPay?",
    a: "The Telegram Channel Gateway by OxaPay is a tool that enables Telegram channel administrators to set up paid memberships and accept crypto payments from their subscribers.",
  },
  {
    q: "How to manage paid channels with OxaPay?",
    a: "With the Telegram Channel Gateway by OxaPay, administrators can automate tasks like adding and removing members based on payment status, making it a more efficient process. Additionally, you can access analytics and data to track the channel's performance, ensuring a smooth management experience.",
  },
  {
    q: "What are the benefits of using a Telegram Channel Gateway by OxaPay?",
    a: "Benefits include simplified payment processes, flexibility with cryptocurrency support, automated management, and in-depth analytics for channel growth.",
  },
  {
    q: "How to set up the Telegram Channel Gateway with OxaPay?",
    a: `Setting up the Telegram Channel Gateway with OxaPay is a straightforward process: <br/>1. Create an OxaPay account. <br/>2. Access the Telegram Channel Gateway in your account. <br/>3. Click on the 'configure gateway' button. <br/>4. Start the OxaPay Channel Gateway bot. <br/>5. Add the bot to your private channel as an admin. <br/>6. Connect your channel to the gateway by forwarding any message to the bot. <br/>7. Define membership plans and their associated costs. <br/>8. Share your invite link with members, and you're ready to accept payments. <br/><br/>Refer to OxaPay's official <a href="https://docs.oxapay.com/integrations/telegram-channel-gateway/configuration" target="_blank" rel="noreferrer">documentation<a/> for comprehensive instructions.`,
  },
  {
    q: "What are the fees associated with using a Telegram Channel Gateway?",
    a: "OxaPay offers competitive and transparent pricing for using the Telegram Channel Gateway. For detailed cost information, you can visit our pricing page on the OxaPay website.",
  },
  {
    q: "Which cryptocurrencies can I use in the Telegram Channel Gateway?",
    a: "The Telegram Channel Gateway supports a wide range of cryptocurrencies, including Bitcoin and Ethereum, for payment convenience. You can find the complete list of supported digital currencies on the OxaPay website.",
  },
  {
    q: "Is technical expertise required to set up and manage the Telegram Channel Gateway?",
    a: "No, technical expertise is not necessary. OxaPay provides user-friendly tools and step-by-step instructions for ease of use.",
  },
  {
    q: "Can multiple channels be managed simultaneously using the Telegram Channel Gateway?",
    a: "Yes, channel owners can manage multiple channels concurrently, streamlining their payment processing and member management.",
  },
  {
    q: "Can I modify membership plans and supported cryptocurrencies after setup?",
    a: "Yes, administrators can make changes to membership plans and the selection of supported cryptocurrencies after the initial setup of the Telegram Channel Gateway. This flexibility allows you to adapt to evolving needs and preferences.",
  },
  {
    q: "Permissions for adding the OxaPay Channel Gateway bot?",
    a: "The bot needs 'Add Subscribers' permission to manage member additions and removals based on payment status.",
  },
];

export const donationQuestions = [
  {
    q: "Why Accept Donations with Crypto?",
    a: "Accepting donations with crypto provides a decentralized, efficient, and secure way for individuals and organizations to receive financial support from a global audience. It opens up new opportunities for fundraising and simplifies cross-border transactions.",
  },
  {
    q: "Crypto Donations vs Traditional Methods?",
    a: "Compared to traditional donation methods, crypto donations offer lower transaction fees, faster processing times, and the potential for increased privacy. They also provide a more accessible way for donors to support causes they care about.",
  },
  {
    q: "How do Crypto Donations Work?",
    a: "Crypto donations work by donors sending digital assets to the recipient's crypto wallet address. This process is facilitated by crypto payment gateways like OxaPay, which streamline the donation process and offer various payment options.",
  },
  {
    q: "What Are the Benefits of Accepting Crypto Donations?",
    a: "Accepting crypto donations can reduce transaction costs, reach a global donor base, enhance financial transparency, and simplify the donation process for both donors and recipients.",
  },
  {
    q: "Are Crypto Donations Safe?",
    a: "Crypto donations are secure due to blockchain technology's robust encryption and transparency. They help protect the integrity of transactions while offering privacy to donors.",
  },
  {
    q: "How to Accept Crypto Donations?",
    a: "To accept crypto donations, you can use OxaPay's Donation feature. Sign up for an OxaPay account, create a donation link, and share it with potential donors.",
  },
  {
    q: "What are the benefits of OxaPay donation?",
    a: "OxaPay's donation feature offers unique benefits beyond the standard advantages. Notable features include a strong emphasis on user privacy, enabling anonymous and secure contributions. The platform supports a wide range of cryptocurrencies, making it convenient for donors. Customization options enhance engagement, and its worldwide accessibility and transparent fee structure cater to both individuals and organizations, making it an efficient fundraising platform.",
  },
  {
    q: "Which Cryptocurrencies Can I Accept for Donations with OxaPay?",
    a: "OxaPay supports various cryptocurrencies for donations, including Bitcoin, Ethereum, Litecoin, Monero, and more. You can find the complete list of supported digital currencies on the OxaPay website.",
  },
  {
    q: "How to Create a Crypto Donation Link?",
    a: `To create a crypto donation link with OxaPay: <br/>1. Sign up for an OxaPay account. <br/>2. Access the 'Donation' page. <br/>3. Customize your link with crypto options. <br/>4. Click 'Create Donation' to get your unique link. <br/>5. Share it on your website or social media. <br/>6. Donors can securely contribute. <br/>7. Easily manage donations through OxaPay. <br/><br/>Refer to OxaPay's official <a href="https://docs.oxapay.com/integrations/donations" target="_blank" rel="noreferrer">documentation<a/> for comprehensive instructions.`,
  },
  {
    q: "Can I Customize My Donation Link?",
    a: "Yes, you can personalize your donation link to match your brand or message.",
  },
  {
    q: "What Are the Fees for Accepting Crypto Donations with OxaPay?",
    a: "For detailed fee information, you can visit the OxaPay website's pricing page.",
  },
  {
    q: "How to Track and Manage Crypto Donations?",
    a: "Use OxaPay to easily track and manage your crypto donations, view transaction history, and manage wallets in one place.",
  },
  {
    q: "Are Technical Skills Required to Use Crypto Donations?",
    a: "No technical expertise is needed to use OxaPay's Donation feature; it offers a user-friendly interface.",
  },
  {
    q: "Is OxaPay Suitable for Both Individuals and Organizations Looking to Accept Crypto Donations?",
    a: "Yes, OxaPay is suitable for both individuals and organizations, offering a simple and efficient solution for accepting crypto donations.",
  },
  {
    q: "Can I Accept Crypto Donations from Worldwide Donors?",
    a: "Yes, you can accept crypto donations from donors worldwide, free from traditional banking limitations.",
  },
  {
    q: "Can I Accept Multiple Cryptocurrencies Simultaneously with a Single OxaPay Donation Link?",
    a: "Yes, you can accept multiple cryptocurrencies simultaneously using a single OxaPay donation link.",
  },
  {
    q: "Are There Any Limits on the Number of Donation Links I Can Create with OxaPay?",
    a: "There are no stated limits on the number of donation links you can create with OxaPay.",
  },
];

export const pluginPublicQuestions = (pluginName: string) => [
  {
    q: `What are the costs of using the OxaPay ${pluginName} plugin?`,
    a: `There are no upfront costs to install and activate OxaPay ${pluginName} plugin. OxaPay earns its commission from the transactions processed through the ${pluginName} plugin. For detailed information on commissions, you can visit our pricing page on the OxaPay website.`,
  },
  {
    q: `What cryptocurrencies does OxaPay ${pluginName} plugin support?`,
    a: `OxaPay's ${pluginName} plugin supports a range of cryptocurrencies, offering customers payment flexibility. You can find the complete list of supported digital currencies on the OxaPay website.`,
  },
  {
    q: `Are OxaPay ${pluginName} plugins safe?`,
    a: `Yes, OxaPay prioritizes security and employs encryption methods to ensure the safety of cryptocurrency transactions processed through ${pluginName}.`,
  },
  {
    q: `Will integrating OxaPay ${pluginName} plugin affect my site’s performance?`,
    a: `Integrating OxaPay into ${pluginName} is designed to be efficient and should not significantly impact your site's performance.`,
  },
  {
    q: `Will OxaPay's ${pluginName} plugin work for businesses of all sizes?`,
    a: `Yes, OxaPay's ${pluginName} plugin is suitable for businesses of all sizes and provides a secure and versatile payment solution.`,
  },
];

export const pluginPrivateQuestions = (coinName: string) => {
  switch (coinName.toLowerCase()) {
    case "whmcs":
      return [
        {
          q: "What is WHMCS?",
          a: "WHMCS, short for 'Web Host Manager Complete Solution,' is a comprehensive software designed to assist businesses in managing various aspects of web hosting and online services. It serves as an all-in-one solution for tasks such as billing, customer support, and automation.",
        },
        {
          q: "What are the advantages of using WHMCS?",
          a: "WHMCS offers several advantages, including automated tasks, streamlined client management, efficient invoicing, robust customer support, and seamless integration with various payment gateways, enhancing overall business efficiency and customer satisfaction.",
        },
        {
          q: "Is it possible to accept cryptocurrency payments on WHMCS?",
          a: "Yes, you can accept cryptocurrency payments with WHMCS by integrating a reliable crypto payment gateway such as OxaPay.",
        },
        {
          q: "What are the advantages of offering crypto payments on WHMCS?",
          a: "Accepting cryptocurrency payments expands your customer base, enhances security through blockchain technology, provides global payment reach, eliminates transaction fees, and offers a modern payment option for clients.",
        },
        {
          q: "What is the OxaPay WHMCS plugin?",
          a: "The OxaPay WHMCS plugin seamlessly integrates cryptocurrency payments, supporting various digital currencies like Bitcoin and Ethereum, providing enhanced security and a global reach for businesses in just a few clicks.",
        },
        {
          q: "How to integrate OxaPay WHMCS plugin on my website?",
          a: `To integrate the OxaPay WHMCS plugin: <br/>1. Install WHMCS. <br/>2. Download and install the OxaPay module. <br/>3. Configure OxaPay by entering your merchant API key. <br/>4. Create products for your services within WHMCS. <br/><br/>Refer to OxaPay's official <a href="https://docs.oxapay.com/integrations/plugins/whmcs" target="_blank" rel="noreferrer">documentation<a/> for comprehensive instructions.`,
        },
      ];
    case "woocommerce":
      return [
        {
          q: "What is WooCommerce plugin?",
          a: "WooCommerce is a plugin for WordPress that transforms your website into a powerful e-commerce platform, allowing you to sell products and services online.",
        },
        {
          q: "What are the advantages of using WooCommerce?",
          a: "WooCommerce offers features like product flexibility, customization, seamless integration, various payment gateways, efficient inventory management, and SEO-friendly design, making it ideal for online stores.",
        },
        {
          q: "Is it possible to accept cryptocurrency payments on WooCommerce?",
          a: "Yes, by using OxaPay's WooCommerce plugin, you can easily integrate cryptocurrency payment options into your WooCommerce store.",
        },
        {
          q: "What are the advantages of offering crypto payments on WooCommerce?",
          a: "Accepting cryptocurrency payments enhances security, expands your global customer base, reduces transaction costs, ensures fast order processing, fosters financial inclusion, and appeals to tech-savvy customers.",
        },
        {
          q: "What is the OxaPay WooCommerce plugin?",
          a: "The OxaPay WooCommerce plugin is a user-friendly tool designed to integrate OxaPay's cryptocurrency payment gateway into your WooCommerce online store in the shortest time and with a few clicks.",
        },
        {
          q: "How to integrate OxaPay WooCommerce plugin on my website?",
          a: `To integrate the OxaPay WooCommerce plugin on your website: <br/>1. Ensure WooCommerce is installed. <br/>2. Download the OxaPay plugin from the official OxaPay website. <br/>3. Activate the OxaPay plugin. <br/>4. Configure OxaPay in the WooCommerce settings with your OxaPay merchant key. <br/>5. Offer cryptocurrency payment options during checkout. <br/><br/>Refer to OxaPay's official <a href="https://docs.oxapay.com/integrations/plugins/woocommerce" target="_blank" rel="noreferrer">documentation<a/> for comprehensive instructions.`,
        },
      ];
    case "easy-digital-downloads":
      return [
        {
          q: "What is Easy Digital Downloads plugin?",
          a: "Easy Digital Downloads is a WordPress plugin designed for selling digital products online, such as ebooks, software, and music. It simplifies the setup and management of online stores for digital goods.",
        },
        {
          q: "What are the advantages of using Easy Digital Downloads?",
          a: "Easy Digital Downloads specializes in digital products, offers high flexibility and customization, streamlines product management, and provides a user-friendly shopping cart and checkout. It also supports a variety of extensions for enhanced functionality, offers robust reporting and analytics, and ensures secure transactions.",
        },
        {
          q: "Is it possible to accept cryptocurrency payments on Easy Digital Downloads?",
          a: "Yes, you can accept cryptocurrency payments on Easy Digital Downloads by using plugins like OxaPay.",
        },
        {
          q: "What are the advantages of offering crypto payments on Easy Digital Downloads?",
          a: "Offering cryptocurrency payments on Easy Digital Downloads enhances security through blockchain technology, extends global accessibility, reduces transaction fees, speeds up transactions, fosters financial inclusion, and prioritizes privacy.",
        },
        {
          q: "What is the OxaPay Easy Digital Downloads plugin?",
          a: "The OxaPay Easy Digital Downloads (EDD) plugin allows you to seamlessly integrate cryptocurrency payments into your EDD-powered online store, expanding payment options for your customers in the shortest time and with a few clicks.",
        },
        {
          q: "How to integrate OxaPay EDD plugin on my website?",
          a: `To integrate OxaPay EDD plugin on your website: <br/>1. Install WordPress from your Cpanel. <br/>2. Install and activate Easy Digital Download and OxaPay plugins in your WordPress admin panel. <br/>3. Configure OxaPay in Easy Digital Download settings using your OxaPay merchant key. <br/>4. Create products in Easy Digital Download by adding file URLs, filling required fields, and publishing. <br/><br/>Refer to OxaPay's official <a href="https://docs.oxapay.com/integrations/plugins/easy-digital-downloads" target="_blank" rel="noreferrer">documentation<a/> for comprehensive instructions.`,
        },
      ];
    case "paid-memberships-pro":
      return [
        {
          q: "What is Paid Membership Pro plugin?",
          a: "Paid Membership Pro is a WordPress plugin designed to help manage memberships and subscriptions on your website. It simplifies the process of creating and managing membership levels, pricing, and access restrictions, making it ideal for websites offering premium content or services.",
        },
        {
          q: "What are the advantages of using Paid Membership Pro?",
          a: "Advantages of Paid Membership Pro include the ability to create multiple membership levels, protect content for members only, set up recurring payments for a steady revenue stream, offer discount codes, and support various payment gateways.",
        },
        {
          q: "Is it possible to accept cryptocurrency payments on Paid Membership Pro?",
          a: "Yes, it is possible to accept cryptocurrency payments on Paid Membership Pro through the integration of the OxaPay Paid Membership Pro plugin.",
        },
        {
          q: "What are the advantages of offering crypto payments on Paid Membership Pro?",
          a: "Offering crypto payments on Paid Membership Pro enhances payment flexibility, adds security through blockchain technology, expands your global reach, eliminates transaction fees, and caters to cryptocurrency enthusiasts.",
        },
        {
          q: "What is the OxaPay Paid Membership Pro plugin?",
          a: "The OxaPay Paid Membership Pro plugin is a seamless integration that enables cryptocurrency payments for your Paid Membership Pro-powered website, offering a secure and versatile payment experience to your members in just a few clicks.",
        },
        {
          q: "How to integrate OxaPay Paid Membership Pro plugin on my website?",
          a: `To integrate OxaPay Paid Membership Pro plugin on your website: <br/>1. Install WordPress. <br/>2. Install Paid Memberships Pro and OxaPay plugin. <br/>3. Configure OxaPay with Paid Memberships Pro. <br/>4. Create products in Paid Memberships Pro. <br/><br/>Refer to OxaPay's official <a href="https://docs.oxapay.com/integrations/plugins/paid-memberships-pro" target="_blank" rel="noreferrer">documentation<a/> for comprehensive instructions.`,
        },
      ];
    case "gravity-forms":
      return [
        {
          q: "What is Gravity Forms plugin?",
          a: "Gravity Forms is a WordPress plugin that simplifies the process of creating various forms for your website, such as contact forms, surveys, and order forms. It offers a user-friendly interface for form building without the need for coding skills.",
        },
        {
          q: "What are the advantages of using Gravity Forms?",
          a: "Gravity Forms provides an easy form-building experience with features like various field types and conditional logic. It's user-friendly and integrates seamlessly with other tools and services you use, such as email marketing and payment gateways.",
        },
        {
          q: "Is it possible to accept cryptocurrency payments on Gravity Forms?",
          a: "Yes, with the OxaPay Gravity Forms Plugin, you can seamlessly integrate cryptocurrency payments into your forms, offering customers the option to pay with digital currencies like Bitcoin, Ethereum, and TRON.",
        },
        {
          q: "What are the advantages of offering crypto payments on Gravity Forms?",
          a: "Accepting crypto payments widens your customer base to crypto enthusiasts. It simplifies transactions, offers top-notch security, and provides a user-friendly design. You can also withdraw earnings to your cryptocurrency wallet.",
        },
        {
          q: "What is the OxaPay Gravity Forms plugin?",
          a: "The OxaPay Gravity Forms Plugin is a tool that allows you to seamlessly integrate cryptocurrency payments into your Gravity Forms, providing customers with the option to pay with cryptocurrencies like Bitcoin, Ethereum, and TRON, all in the shortest time and with a few clicks.",
        },
        {
          q: "How to integrate OxaPay Gravity Forms plugin on my website?",
          a: `To integrate OxaPay Gravity Forms plugin on your website: <br/>1. Install WordPress. <br/>2. Get and install Gravity Forms. <br/>3. Activate Gravity Forms. <br/>4. Install the 'OxaPay' plugin for Gravity Forms. <br/>5. Configure OxaPay by adding your OxaPay merchant API key. <br/>6. Create forms in Gravity Forms. <br/><br/>Refer to OxaPay's official <a href="https://docs.oxapay.com/integrations/plugins/gravity-forms" target="_blank" rel="noreferrer">documentation<a/> for comprehensive instructions.`,
        },
      ];
    default:
      // Return an empty array or handle unknown coin names as needed
      return [];
  }
};

export const referralProgramQuestions = [
  {
    q: "What is OxaPay's Referral Program?",
    a: "OxaPay's Referral Program is designed to reward users for introducing new customers to the platform. By sharing your unique referral link, you can earn a commission on each payment transaction made by your referrals. The more people you refer, the more you can earn.",
  },
  {
    q: "How does the OxaPay referral program work?",
    a: "The OxaPay referral program works by providing users with a referral link that they can share with others. When someone signs up and makes payment transactions using OxaPay through your referral link, you earn a commission on the transaction fees they generate.",
  },
  {
    q: "How can I join OxaPay's Referral Program?",
    a: "To join OxaPay's Referral Program, you need to register for an OxaPay account. After signing up, you will be provided with a unique referral link that you can start sharing.",
  },
  {
    q: "What rewards can I earn through OxaPay's Referral Program?",
    a: "Through OxaPay's Referral Program, you can earn a 30% commission on the transaction fees generated by your referrals.",
  },
  {
    q: "Is there a limit to the number of referrals I can make?",
    a: "There is no limit to the number of referrals you can make. The more people you refer, the more you can potentially earn.",
  },
  {
    q: "How can I track my referrals and earnings?",
    a: "You can track your referrals and earnings through the OxaPay account dashboard, providing real-time updates on your commissions.",
  },
  {
    q: "Can I refer someone who already has an OxaPay account?",
    a: "No, you cannot refer someone who already has an existing OxaPay account. The referral program is designed for bringing in new users who are not already registered with OxaPay.",
  },
  {
    q: "Is there a time limit for earning referral commissions?",
    a: "No, there is no time limit for earning referral commissions with OxaPay. You can continue to earn commissions as long as your referrals use OxaPay's services.",
  },
  {
    q: "What is OxaPay Referral Plus?",
    a: "OxaPay Referral Plus is an innovative addition to the Referral Program, allowing OxaPay users to automatically become potential referrals of existing users. This program simplifies the process of earning referral commissions.",
  },
  {
    q: "How is OxaPay Referral Plus different from the standard referral program?",
    a: "OxaPay Referral Plus is different from the standard Referral Program as it automatically enrolls users as potential referrals when they use OxaPay services through your invoice, expanding your referral network.",
  },
  {
    q: "Is OxaPay Referral Plus available to all OxaPay users?",
    a: "OxaPay Referral Plus is available to all OxaPay users, making it accessible to users from around the world.",
  },
  {
    q: "What percentage of commissions can I earn through OxaPay Referral Plus?",
    a: "Through OxaPay Referral Plus, you can earn a remarkable 30% commission on the transaction fees generated by your referrals.",
  },
  {
    q: "Is there a limit to the number of referrals I can have through OxaPay Referral Plus?",
    a: "OxaPay Referral Plus doesn't impose a limit on the number of referrals you can have, allowing for the potential to maximize your passive income.",
  },
  {
    q: "Is OxaPay Referral available to users in all countries?",
    a: "For information regarding countries with restrictions, please refer to the Terms & Conditions in the footer.",
  },
];

export const payoutAPIQuestions = [
  {
    q: "What is OxaPay Payout API?",
    a: "The OxaPay Payout API is a feature that facilitates mass and automatic transfers of cryptocurrency assets. It acts as a bridge between businesses and cryptocurrency payments, offering two distinct modes: internal and external, each designed to meet specific business requirements.<br/><br/>",
  },
  {
    q: "What are the benefits of OxaPay Payout API?",
    a: "Using the OxaPay Payout API provides numerous benefits including simple and automated Mass transfers, cost savings, flexibility in transfer options, and increased user satisfaction. Businesses can use OxaPay Payout API to streamline their transaction processes.<br/><br/>",
  },
  {
    q: "Difference between internal and external payment mode?",
    a: "<b>Internal Mode:</b><br/>Transfers within OxaPay.<br/>No fees, no minimum amount.<br/>Ideal for fast and fee-free transactions within OxaPay.<br/><br/><b>External Mode:</b><br/>Transfers outside OxaPay.<br/>Network fees, minimum amount.<br/>Useful for payments to recipients without OxaPay accounts.<br/><br/>",
  },
  {
    q: "When should I use internal payment mode?",
    a: "Internal payment mode is ideal for fee-free and high-speed transactions, especially for micropayments, bulk and automatic transfers, and for users within the OxaPay ecosystem.<br/><br/>",
  },
  {
    q: "What are the advantages of internal payment mode?",
    a: "The advantages of internal payment mode include zero fees, no minimum payment amount, fast transfer speeds, and the capability to handle mass payments and micropayments efficiently.<br/><br/>",
  },
  {
    q: "When is external payment mode useful?",
    a: "External payment mode becomes invaluable when making payments to users outside the OxaPay ecosystem, making it an excellent choice for businesses that require greater flexibility in their payment processes.<br/><br/>",
  },
  {
    q: "Which cryptocurrencies are supported by the OxaPay Payout API?",
    a: "The OxaPay Payout API supports a range of cryptocurrencies, offering users flexibility in payment options. You can find the complete list of supported digital currencies on the OxaPay website.<br/><br/>",
  },
  {
    q: "How to get started with OxaPay Payout API?",
    a: `To get started with OxaPay Payout API:<br/><br/>1. Create an OxaPay account.<br/>2. Enable Two-Factor Authentication (2FA).<br/>3. Access the Payout API page in your OxaPay account.<br/>4. Generate a new API key.<br/>5. Optionally enhance security by specifying allowed IPs and setting transfer limits.<br/>6. Copy and store the API key securely.<br/>7. Integrate the API key into your website or app.<br/>8. Start initiating cryptocurrency payouts with confidence.<br/>9. Monitor and optimize your transactions for better efficiency.<br/><br/>Refer to OxaPay's official <a href="https://docs.oxapay.com/api-reference/creating-payout" target="_blank" rel="noreferrer">documentation<a/> for comprehensive instructions.<br/><br/>`,
  },
  {
    q: "Is OxaPay Payout API reliable?",
    a: "Yes, OxaPay Payout API is reliable. It offers efficient cryptocurrency mass transfers with strong security measures, including Two-Factor Authentication (2FA) and IP restrictions. Additionally, OxaPay's commitment to innovation ensures its continued relevance in the evolving financial landscape, making it a dependable solution for businesses.<br/><br/>",
  },
  {
    q: "What is the cost of using OxaPay Payout API?",
    a: "The cost of using OxaPay Payout API can vary depending on the mode you choose:<br/><br/><b>Internal Payouts:</b> These are fee-free, making them a cost-effective choice for businesses.<br/><br/><b>External Payouts:</b> This mode incurs network fees, so businesses need to consider these costs, which can vary based on the cryptocurrency and network used.<br/>For detailed fee information, you can visit our pricing page on the OxaPay website.<br/><br/>",
  },
  {
    q: "What training resources are available for the OxaPay Payout API?",
    a: "OxaPay provides comprehensive training and support resources, including blogs and documents, to help businesses effectively utilize OxaPay Payout API. Additionally, you can find answers to frequently asked questions on the OxaPay customer support page.<br/><br/>",
  },
];

export const merchantAPIQuestions = [
  {
    q: "What are Crypto Merchant Services?",
    a: "Crypto Merchant Services enable businesses to accept cryptocurrency as payment for goods and services. These services provide a secure, convenient, and efficient way to process transactions in various cryptocurrencies.",
  },
  {
    q: "What is OxaPay's Merchant Service?",
    a: "OxaPay's Merchant Service is a user-friendly solution empowering webmasters and developers to seamlessly integrate cryptocurrency payments into websites, applications, and Telegram bots.",
  },
  {
    q: "How does OxaPay's Merchant Service benefit businesses?",
    a: "It allows businesses to tap into the growing cryptocurrency market, offering a secure, fast, and user-friendly way to accept payments. It can help increase customer satisfaction by providing more payment options and enhance operational efficiency through features like Auto Convert and transaction analysis.",
  },
  {
    q: "How do I start using OxaPay's Merchant Service?",
    a: "To begin using OxaPay's Merchant Web Service, follow these steps:<br/>1) Create an Account: Sign up on the OxaPay website.<br/>2) Visit the Merchant API Page: Access the Merchant API page to set up your account.<br/>3) Generate a Merchant API Key: Create a Merchant API key in your dashboard.<br/>4) Customize Integration: Choose where and how you want to integrate OxaPay – whether it's on your website, app, or bot.<br/>5) Start Accepting Payments: Once your integration is set, you can begin accepting cryptocurrency payments.<br/>6) Monitor Transactions: Keep an eye on payments and transaction history using the user-friendly dashboard.<br/>Refer to OxaPay's official documentation for comprehensive instructions.",
  },
  {
    q: "Can beginners with limited technical knowledge use OxaPay's Merchant Service?",
    a: "Absolutely. OxaPay's Merchant Service is designed to cater to both experienced webmasters and beginners, ensuring a simplified integration process.",
  },
  {
    q: "What documentation or resources are available to get started with OxaPay's Merchant Service?",
    a: "OxaPay provides comprehensive training and support resources, including blogs and documents, to help businesses effectively utilize OxaPay's Merchant Web Service. Additionally, you can find answers to frequently asked questions on the OxaPay customer support page.",
  },
  {
    q: "What is the cost of using OxaPay's Merchant Service?",
    a: "OxaPay offers competitive and transparent pricing for Merchant Service. For detailed fee information, you can visit our pricing page on the OxaPay website.",
  },
  {
    q: "Which cryptocurrencies are supported by OxaPay's Merchant Web Service?",
    a: "OxaPay supports a wide variety of cryptocurrencies. You can find the complete list of supported cryptocurrencies on the OxaPay website.",
  },
  {
    q: "What is a Merchant API key and why do I need one?",
    a: "A Merchant API key is a unique identifier that allows secure communication between your business platform and OxaPay's services. It's required to authenticate and process cryptocurrency transactions through your website or app.",
  },
  {
    q: "How can I generate a Merchant API key with OxaPay?",
    a: "Log into your OxaPay account, navigate to the Merchant API section, and click on the option to generate a new API key. Follow the prompts to configure and obtain your key.",
  },
  {
    q: "Can OxaPay's Merchant API be integrated into any website or application?",
    a: "Yes, OxaPay's Merchant API is designed to be flexible and can be integrated into various websites and applications, regardless of the platform they're built on.",
  },
  {
    q: "What is the OxaPay Invoice API and What businesses can use it?",
    a: "The OxaPay Invoice API is a tool that enables businesses to easily create and manage cryptocurrency invoices, facilitating secure and straightforward crypto payments. This API is versatile, making it suitable for a wide range of businesses including e-commerce sites, service providers, retail stores, and any other businesses looking to accept cryptocurrency payments. Its ease of integration and support for various cryptocurrencies make it an ideal payment solution for companies aiming to expand their payment options and cater to customers worldwide.",
  },
  {
    q: "What is a OxaPay White-Label solution and how does it benefit my business?",
    a: "The OxaPay White-Label solution allows businesses to offer OxaPay's cryptocurrency payment services under their own brand name. This means companies can provide secure and efficient crypto payment options to their customers, using OxaPay's technology, but with the business's branding and identity. It's ideal for businesses wanting to seamlessly integrate crypto payments into their services while maintaining their brand's look and feel.",
  },
  {
    q: "What is the OxaPay Static Address solution and how does it increase transaction efficiency?",
    a: "The OxaPay Static Address solution provides a single, unchanging cryptocurrency address for all transactions, making it simpler for businesses to receive payments. This increases transaction efficiency by eliminating the need to generate a new address for each transaction, streamlining the payment process, and making it easier to track and manage incoming payments. It's especially useful for businesses looking for a straightforward and reliable way to accept cryptocurrency payments.",
  },
  {
    q: "What is the OxaPay invoice bot and what businesses benefit from it the most?",
    a: "The OxaPay invoice bot is a tool designed for the Telegram platform, enabling businesses to easily generate and manage cryptocurrency invoices. This bot is particularly beneficial for online businesses without a website, local businesses, freelancers and any social media based business. It simplifies accepting crypto payments by providing a user-friendly way to create and send invoices, making it ideal for small to medium-sized businesses looking to tap into the cryptocurrency market.",
  },
  {
    q: "What is OxaPay Auto Convert and how does it work?",
    a: "OxaPay Auto Convert is a feature that automatically converts cryptocurrency payments into USDT (Tether) the moment they're received. This means when you get paid in different cryptocurrencies, Auto Convert instantly changes them to USDT,protecting businesses from market volatility . It's a handy tool for businesses looking to minimize financial risk and simplify their cryptocurrency transactions.",
  },
  {
    q: "What is the advantage of using Auto Withdrawal with OxaPay?",
    a: "The advantage of using Auto Withdrawal with OxaPay is that it allows businesses to automatically transfer their cryptocurrency payments directly into their personal wallet instantly. This feature ensures that you have immediate access to your funds, enhances liquidity, and reduces the time and effort needed to manually move your crypto earnings. It's a convenient way to streamline your payment process and keep your finances fluid and accessible.",
  },
  {
    q: "How can I analyze cryptocurrency transactions with OxaPay's transaction Analysis system?",
    a: "Use OxaPay's transaction Analysis system to view and analyze your crypto transaction history. It offers tools for filtering, searching, and gaining insights into your payment activities, aiding in financial management and strategy development.",
  },
];

export const oxaPayCryptoInvoiceFAQs = [
  {
    q: "What is the OxaPay Crypto Invoice?",
    a: "OxaPay Crypto Invoice is a digital payment solution that allows businesses to seamlessly integrate cryptocurrency payment options into their websites or applications. This service enables merchants to accept payments in various cryptocurrencies, providing a convenient and secure way for customers to pay for goods and services online. By integrating OxaPay's system, businesses can easily generate payment requests for their customers, who can then complete the transaction using their preferred cryptocurrency. This process not only simplifies the transaction for both parties but also leverages the security and efficiency of blockchain technology for financial transactions.",
  },
  {
    q: "What are the benefits of using OxaPay Crypto Invoice?",
    a: "<b>There are several benefits to using OxaPay Crypto Invoice:</b> <br/>- Seamless Integration <br/>- Enhanced Security <br/>- Transparent Fees <br/>- Multiple Cryptocurrencies <br/>- Instant Settlements & Auto-withdrawals <br/>- User-Friendly Interface <br/>- Customizable Invoices <br/>- Detailed Analytics <br/>- 24/7 Customer Support",
  },
  {
    q: "What kind of businesses is OxaPay Crypto Invoice ideal for?",
    a: "Ideal for a wide range of sectors including: e-commerce, digital goods and services, technology, professional services, gaming, and any business looking to accept global crypto payments.",
  },
  {
    q: "Is OxaPay Crypto Invoice safe and secure?",
    a: "Yes, it leverages blockchain technology for encrypted transactions and secure data handling, offering a high level of security and privacy.",
  },
  {
    q: "Do I need a KYC/KYB verification to use OxaPay Crypto Invoice?",
    a: "No, OxaPay allows for merchant account registration without the need for KYC/KYB verification, simplifying the setup process.",
  },
  {
    q: "How can OxaPay help me manage crypto payment volatility?",
    a: "OxaPay mitigates crypto payment volatility by offering an auto-convert feature to USDT, a stablecoin, ensuring your revenue remains stable despite market fluctuations. This provides a safeguard against the unpredictable nature of cryptocurrency values, helping businesses maintain consistent earnings.",
  },
  {
    q: "Does OxaPay support instant withdrawals?",
    a: "Yes, OxaPay supports instant withdrawals, allowing merchants to automatically transfer their crypto payments into their wallets swiftly. This feature ensures that businesses have immediate access to their funds, enhancing cash flow and operational efficiency without unnecessary delays.",
  },
  {
    q: "What analytics and reporting tools does OxaPay offer for businesses?",
    a: "OxaPay provides a comprehensive dashboard with analytics and reporting tools. You can track your transactions, monitor sales trends, and gain valuable insights to inform your business decisions.",
  },
  {
    q: "How can my customers have blockchain fee-free payments in OxaPay?",
    a: "Customers can achieve blockchain fee-free payments with OxaPay by utilizing the OxaPay Telegram Wallet. This special feature is accessible when paying business invoices generated via OxaPay's crypto payment gateway. To take advantage of fee-free transactions, customers should ensure their OxaPay Telegram Wallet has enough cryptocurrency to cover the payment, allowing for straightforward, cost-effective transactions without additional network fees.",
  },
  {
    q: "Is OxaPay Crypto Invoice mobile-friendly?",
    a: "Absolutely, OxaPay is optimized for mobile use, offering businesses and their customers a smooth and accessible payment interface across smartphones and tablets for convenient transactions anywhere.",
  },
  {
    q: "How can I ensure my financial records are always accurate with OxaPay Crypto Invoice?",
    a: "OxaPay's platform processes transactions instantly, updating financial records in real time to ensure accuracy and reliability, eliminating the discrepancies common in manual record-keeping.",
  },
  {
    q: "How can I be sure I'm getting the best exchange rates when using OxaPay Crypto Invoice?",
    a: "OxaPay provides real-time conversion rates for all supported cryptocurrencies, ensuring you get fair and accurate pricing for transactions.",
  },
  {
    q: "Can I design invoices that match my brand image with OxaPay Crypto Invoice?",
    a: "Yes, OxaPay allows for customizable invoices, enabling you to create invoices that reflect your brand's identity.",
  },
  {
    q: "What are the benefits of using OxaPay's Telegram invoice feature?",
    a: "The Telegram invoice feature provides a seamless way to integrate OxaPay with Telegram bots and businesses operating on the platform. It offers a user-friendly interface that aligns with Telegram's design, making it easy for customers to pay directly within the app. This feature streamlines the payment process and enhances customer satisfaction for businesses using Telegram.",
  },
  {
    q: "How can I contact OxaPay customer support if I have questions?",
    a: "OxaPay provides 24/7 customer support through various channels, including email, live chat, and support tickets on their website.",
  },
  {
    q: "What resources does OxaPay offer to help me integrate their service?",
    a: "OxaPay provides comprehensive resources to help you integrate their service: <br/> - <b>Detailed Documentation:</b> Step-by-step guides and API documentation to facilitate a smooth integration process. <br/> - <b>Technical Support:</b> Access to technical support to answer your questions and assist with any technical difficulties during integration. <br/> - <b>Sandbox Environment:</b> A testing environment to test the integration before going live on your website or app. By combining these resources with OxaPay's user-friendly approach, integrating their Crypto Invoice service becomes easier and more accessible for businesses of all technical backgrounds.",
  },
];

export const oxapayCryptoWhitelabel = [
  {
    q: "What is a white label crypto payment gateway?",
    a: "A white label crypto payment gateway is a customizable platform provided by a third-party service, allowing businesses to accept cryptocurrency payments under their own brand. This solution enables companies to leverage the benefits of crypto payments without the complexity of developing their own system, offering a seamless, branded experience to their customers.",
  },
  {
    q: "How does OxaPay's white label crypto payment gateway work?",
    a: "OxaPay's gateway integrates with your business, enabling you to accept cryptocurrencies as payment while it handles the technical aspects, security, and compliance behind the scenes.",
  },
  {
    q: "What are the benefits of using a white label crypto payment gateway?",
    a: "Using a white label crypto payment gateway offers businesses the ability to rapidly enter the cryptocurrency market without the need for extensive technical expertise or infrastructure. It provides a cost-effective solution that enhances brand consistency by allowing customization to align with the business's branding. This approach also ensures access to advanced security features, broad cryptocurrency support, and global payment acceptance, all while maintaining the customer experience under the business's brand.",
  },
  {
    q: "How can OxaPay white label payment gateway enhance my brand?",
    a: "OxaPay's white label gateway enhances your brand by allowing customization to match your visual identity, thus offering a consistent, branded payment experience that builds trust and recognition among your customers while leveraging the growing demand for crypto payments.",
  },
  {
    q: "What types of businesses can benefit from OxaPay's white label service?",
    a: "OxaPay's white label service benefits fintech startups, e-commerce platforms, online gaming sites, and any business looking to accept crypto payments while maintaining a cohesive brand identity. It's especially advantageous for companies aiming to quickly adapt to the crypto market with a secure, branded solution.",
  },
  {
    q: "Is OxaPay's white label crypto payment gateway secure?",
    a: "Yes, it employs robust security measures, including encryption and compliance with industry standards, to ensure transaction safety and data protection.",
  },
  {
    q: "Do I need any coding knowledge to integrate OxaPay's white label gateway?",
    a: "Minimal coding knowledge is required for integration, as OxaPay offers comprehensive support and documentation to guide you through the process, making it accessible even for those with limited technical expertise.",
  },
  {
    q: "Does OxaPay's white label gateway require KYC/KYB?",
    a: "OxaPay's white label gateway does not require KYC/KYB procedures from its partners, facilitating ease of doing business and enabling a smoother integration process for merchants looking to adopt cryptocurrency payments quickly and efficiently.",
  },
  {
    q: "Can I customize the look of my OxaPay payment gateway to match my brand?",
    a: "Yes, OxaPay allows full customization of its payment gateway, enabling you to align its design with your brand's visual identity, ensuring a consistent and seamless brand experience for your customers.",
  },
  {
    q: "How does OxaPay's white label gateway integrate with my existing platform?",
    a: "OxaPay's white label gateway integrates seamlessly with your existing platform through APIs, facilitating easy setup and customization. This integration allows for smooth cryptocurrency transactions within your site or app, maintaining a consistent user experience that aligns with your brand's visual and operational standards, without disrupting your current ecosystem.",
  },
  {
    q: "Is OxaPay's white label gateway suitable for e-commerce businesses?",
    a: "Yes, OxaPay's white label gateway is ideal for e-commerce businesses, offering a customizable, secure, and efficient cryptocurrency payment solution that can enhance the shopping experience and expand payment options for customers.",
  },
  {
    q: "How much does OxaPay white label gateway cost? Are there any hidden fees?",
    a: "OxaPay offers transparent pricing plans. Visit the pricing page for more details. OxaPay prides itself on transparency, with no hidden fees.",
  },
  {
    q: "What APIs does OxaPay offer for its white label crypto payment gateway?",
    a: "OxaPay offers comprehensive APIs for its white label crypto payment gateway, including payment processing, transaction management, and account services. These APIs enable seamless integration, customization, and automation of payment operations, catering to various business needs and enhancing the overall payment experience.",
  },
  {
    q: "Does OxaPay's white label gateway support webhooks?",
    a: "Yes, OxaPay's white label gateway supports webhooks, allowing businesses to receive real-time notifications about transaction events. This feature enables automated updates and responses to payment status changes, streamlining operations and ensuring timely communication and processing within your system.",
  },
  {
    q: "What service does white label gateway OxaPay offer to merchants to manage crypto market volatility?",
    a: "OxaPay offers an auto-conversion feature to merchants, allowing them to instantly convert cryptocurrencies to USDT. This service helps in managing crypto market volatility by stabilizing revenue and protecting against sudden price fluctuations.",
  },
  {
    q: "Does OxaPay's white label gateway support automatic withdrawals?",
    a: "Yes, OxaPay's white label gateway supports automatic withdrawals, enabling merchants to seamlessly transfer their cryptocurrency earnings to their preferred accounts. This feature facilitates efficient cash flow management and reduces the administrative effort required to manually process withdrawals.",
  },
  {
    q: "Does OxaPay offer any customer support for its white label gateway?",
    a: "Yes, OxaPay provides dedicated 24/7 customer support for its white label gateway, ensuring assistance with technical issues, integration queries, and operational guidance to facilitate smooth and continuous payment processing for businesses.",
  },
  {
    q: "Does OxaPay offer any developer documentation for its white label gateway?",
    a: "Yes, OxaPay provides extensive developer documentation for its white label gateway, covering integration guides, API references, and usage examples to support developers in implementing and customizing the payment solution efficiently.",
  },
];

export const walletQuestions = [
  {
    q: "What is a cryptocurrency wallet?",
    a: "A cryptocurrency wallet is a digital tool that enables users to store, send, and receive cryptocurrencies. These wallets can be categorized based on their approach to privacy, including transparent wallets, privacy-focused wallets, and hybrid wallets. They vary in accessibility, security features, and focus on user privacy.",
  },
  {
    q: "What is a web-based cryptocurrency wallet?",
    a: "A web-based cryptocurrency wallet, or web wallet, is accessible through a web browser. Unlike software wallets that need to be downloaded, web wallets are hosted online, providing convenience and accessibility from anywhere with an internet connection. Users can manage their cryptocurrency holdings and conduct transactions through their web-based wallet accounts.",
  },
  {
    q: "What is OxaPay wallet?",
    a: "OxaPay wallet is a digital wallet service provided by OxaPay, allowing users to efficiently manage their cryptocurrencies. It offers secure storage, quick transactions, and user-friendly interfaces.",
  },
  {
    q: "How do I create an OxaPay wallet?",
    a: "To create an OxaPay wallet, visit the OxaPay website or app, sign up for an account, and follow the registration process. Once registered, you can set up your wallet and start managing your cryptocurrencies.",
  },
  {
    q: "Are OxaPay wallets safe?",
    a: "Yes, OxaPay wallets prioritize security, incorporating features like encryption and two-factor authentication to protect users' assets. Users should follow best practices for wallet security, such as safeguarding access credentials.",
  },
  {
    q: "Can I manage different cryptocurrencies with OxaPay?",
    a: "Yes, OxaPay supports multiple cryptocurrencies and networks, enabling users to manage a diverse range of digital assets within a single wallet. The complete list of supported cryptocurrencies and networks can be found on the OxaPay website.",
  },
  {
    q: "Are there transaction fees with OxaPay wallets?",
    a: "OxaPay wallets do not impose additional fees beyond the network fee associated with each transaction, determined by the current state of the network. Detailed fee information is available on the OxaPay website.",
  },
  {
    q: "What is OxaPay Telegram web app wallet?",
    a: "The OxaPay Telegram web app wallet is an innovative cryptocurrency wallet accessible and managed directly within the Telegram app. It provides a secure and seamless way for users to handle their cryptocurrency assets while using Telegram.",
  },
  {
    q: "What are the advantages of using OxaPay Telegram web app wallet?",
    a: "The OxaPay Telegram web app wallet offers several advantages, including convenience (managing assets within Telegram), user-friendliness (suitable for beginners and experienced users), security (end-to-end encryption and two-factor authentication), easy setup (linked via Telegram Bot or OxaPay's website/app), and hassle-free asset management (supports various cryptocurrency networks).",
  },
  {
    q: "How do I set up a Telegram web app wallet?",
    a: "To set up a Telegram web app wallet, initiate the OxaPay Telegram Bot or log into your OxaPay account through the website or app. Follow the provided instructions for a hassle-free setup.",
  },
  {
    q: "What happens if I lose my Telegram account?",
    a: "Losing your Telegram account may compromise your access to the Telegram web app wallet. It's crucial to take account recovery steps, such as adding an email address for account retrieval, to prevent potential loss of access.",
  },
  {
    q: "Can I use my wallet for online shopping?",
    a: "In many cases, you can use your OxaPay wallet for online shopping, depending on platforms and merchants that accept cryptocurrencies as a payment method. It provides a convenient way for digital currency transactions.",
  },
  {
    q: "What should I do for more security in my Telegram web app wallet?",
    a: "For enhanced security in your Telegram web app wallet, especially if created through OxaPay and Telegram, provide and verify your email address. Additionally, consider enabling features like two-factor authentication and encryption for added protection.",
  },
];

export const swapQuestions = (pairs: string[]) => [
  {
    q: "What is the OxaPay Swap?",
    a: "OxaPay Swap facilitates rapid asset conversion by enabling instant exchange between different cryptocurrencies or tokens.",
  },
  {
    q: "How does the OxaPay swap work?",
    a: "Access the Swap Page within your account panel, select the desired pair, specify the amount, confirm the transaction, and experience immediate asset conversion.",
  },
  {
    q: "Which cryptocurrencies are supported for swapping on OxaPay?",
    a: `You can swap the below pairs in your panel:<br/>${pairs.join("")}`,
  },
  {
    q: "Are there any limitations on the minimum amounts for swaps?",
    a: "Yes, minimum swap amounts vary per pair. These details can be found by selecting specific pairs on the Swap page within your account panel.",
  },
  {
    q: "Is the swap feature available for both individual users and businesses?",
    a: "Yes, the swap feature is accessible to all users, including individuals and businesses.",
  },
  {
    q: "How long does it take for a swap to be processed?",
    a: "The swap process on OxaPay occurs instantly, providing immediate conversion of assets.",
  },
  {
    q: "Are there any network fees associated with the swap process?",
    a: "No, as the swap utilizes assets from your OxaPay wallet, there are no additional network fees for transferring or processing the swap.",
  },
  {
    q: "Can I view my swap history on the OxaPay platform?",
    a: `Yes, your comprehensive swap history, including transaction details, is accessible via the <a href="https://app.oxapay.com/swap" target="_blank" rel="noreferrer">Swap page<a/> within your account panel.`,
  },
  {
    q: "What is the purpose of the swap feature for merchants?",
    a: "Merchants can use the swap feature to mitigate the risk associated with fluctuating asset prices by converting earned currencies to stable coins like tether, stabilizing their earnings.",
  },
];
