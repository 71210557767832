import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MerchantsContext } from "../../app/Core/MerchantContext";
import { alertsMapSandbox } from "../../app/services/alertsMap";
import { FailedModalFire } from "../../app/services/failedSwalFire";
import { formatAmount } from "../../app/services/functionServices";
import {
  getTokenContractAddress,
  networksAmountMap,
  networksAmountNameMap,
  networkschainIdMap,
  networksNameMap,
} from "../../app/services/maps";
import { useRequest } from "../../app/services/userHttpRequests";
import { CircleProgressBar } from "../progressBar/CircleProgressBar";
import { QrDataWalletSuggest } from "../QrDataWalletSuggest";
import { TextCopyWidget } from "../TextCopyWidget";
import { TimeCounter } from "../TimeCounter";
import { WaitButtonSpan } from "../WaitButtonSpan";

interface props {
  setActiveIndex: (activeIndex: number) => void;
}

export const MerchantStepTwo = ({ setActiveIndex }: props) => {
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [qrLoading, setQrLoading] = useState(true);
  const { price, coinsListMap, merchantsSession, currency } =
    useContext(MerchantsContext);
  const { id } = useParams();
  const { sandboxRequest } = useRequest();

  const sandboxRequestFn = (status: number) => {
    setBtnLoading(true);
    sandboxRequest(id as string, status).then((res) => {
      if (res.status === 200 && res.result === true) {
        setActiveIndex(3);
      } else {
        FailedModalFire(alertsMapSandbox[res.errorNum.toString()]);
      }
      setBtnLoading(false);
    });
  };

  useEffect(() => {
    setQrLoading(true);
  }, [merchantsSession?.wallet]);

  useEffect(() => {
    Number(merchantsSession?.step) === 3 && setActiveIndex(2);
    // eslint-disable-next-line
  }, [merchantsSession?.step]);

  return (
    <>
      {merchantsSession && (
        <div className="p-10">
          <div>
            <h4 className="fs-5 fw-semibold text-gray-800 mb-1">
              Amount to pay
            </h4>
            <TextCopyWidget
              text={formatAmount(
                parseFloat(merchantsSession.amount),
                coinsListMap[merchantsSession.coin]?.displayPrecision
              )}
              addonText={merchantsSession.coin}
              tooltipText="Copy Amount"
              className="fs-5 mb-0 mt-7 fm-jetbrain"
            />
            <span className="text-gray-700 fw-semibold ms-1 fm-jetbrain">
              (
              {currency
                ? price + " " + currency
                : "$" + Number(price).toLocaleString()}
              )
            </span>
            <div>
              <h4 className="bg-light-warning rounded px-2 d-inline-block py-1 fs-5 fw-semibold text-warning mt-0 mb-1">
                Sending less may result in fund loss.
              </h4>
            </div>

            {merchantsSession.sandbox ? (
              <div>
                <button
                  type="button"
                  disabled={btnLoading}
                  onClick={() => sandboxRequestFn(1)}
                  className="btn btn-success my-5 me-2"
                >
                  {btnLoading ? <WaitButtonSpan /> : "Success payment"}
                </button>
              </div>
            ) : (
              <>
                <h4 className="fs-5 fw-semibold text-gray-800 mt-4">
                  Send{" "}
                  <span className="text-primary fw-bold">
                    {coinsListMap[merchantsSession?.coin]?.name}
                  </span>{" "}
                  {!coinsListMap[merchantsSession.coin]?.networkList[
                    merchantsSession.network
                  ]?.isMain && (
                    <span className="badge badge-light-primary text-primary">
                      {merchantsSession.network}
                    </span>
                  )}{" "}
                  to this address
                </h4>
                <TextCopyWidget
                  text={merchantsSession?.wallet}
                  tooltipText="Copy Address"
                  className="fs-5 mb-2 mt-7 fm-jetbrain"
                  qrCode={true}
                />

                <div className="bg-light-primary rounded d-flex flex-column flex-md-row justify-content-center align-items-center mt-10 mb-8 gap-3 p-3">
                  <div>
                    {qrLoading && (
                      <div className="p-2 h-150px w-150px bg-secondary rounded shine"></div>
                    )}
                    {merchantsSession?.wallet && (
                      <div
                        className={`bg-white p-2 rounded d-block ${
                          qrLoading && "d-none"
                        }`}
                      >
                        <img
                          src={`http://api.qrserver.com/v1/create-qr-code/?data=${
                            networksNameMap[merchantsSession.network]
                          }${merchantsSession?.wallet}${
                            networkschainIdMap[merchantsSession.network]
                          }${networksAmountNameMap[merchantsSession.network]}${
                            merchantsSession.network === ("Ton" || "Polygon")
                              ? Math.floor(
                                  Number(merchantsSession.amount) *
                                    networksAmountMap[merchantsSession.network]
                                )
                              : Number(merchantsSession.amount) *
                                networksAmountMap[merchantsSession.network]
                          }${getTokenContractAddress(
                            merchantsSession.coin,
                            merchantsSession.network
                          )}&size=150x150`}
                          alt="QR code"
                          style={{ width: "150px" }}
                          onLoad={() => {
                            setQrLoading(false);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <QrDataWalletSuggest />
                </div>
              </>
            )}

            <div className="d-flex align-items-center mt-3">
              <CircleProgressBar
                duration={Number(merchantsSession.duration)}
                endtime={merchantsSession.expireAt}
              />
              <p className="ms-2 my-0">
                The rate will be updated in{" "}
                <span className="text-primary fw-bold">
                  <TimeCounter endtime={merchantsSession.expireAt} />
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
