import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { Blog } from "../../../components/main/Blog";
import { HomeFeatures } from "../../../components/main/HomeFeatures";
import { MainSection } from "../../../components/main/MainSection";
import { MerchantAPISection } from "../../../components/main/MerchantAPISection";
import { SupportedCoins } from "../../../components/main/SupportedCoins";

export const Home = () => {
  const cookies = new Cookies();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    const ref = params.get("ref");
    ref && cookies.set("ref", ref, { domain: ".oxapay.com", path: "/" });

    // eslint-disable-next-line
  }, []);
  return (
    <>
      <MainSection />
      <SupportedCoins />
      <HomeFeatures />
      <MerchantAPISection />
      {/* <PluginsSection /> */}
      <Blog />
    </>
  );
};
