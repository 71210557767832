import React, { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { MerchantsContext } from "../../app/Core/MerchantContext";
import { ShowAlertContext } from "../../app/Core/ShowAlertContext";
import {
  alertsMapSandbox,
  alertsMapStepOneDonate,
} from "../../app/services/alertsMap";
import { errorHandler } from "../../app/services/errorHandler";
import { FailedModalFire } from "../../app/services/failedSwalFire";
import { formatAmount } from "../../app/services/functionServices";
import {
  networksAmountMap,
  networksAmountNameMap,
  networkschainIdMap,
  networksNameMap,
} from "../../app/services/maps";
import { useRequest } from "../../app/services/userHttpRequests";
import { TextCopyWidget } from "../TextCopyWidget";
import { TimeCounter } from "../TimeCounter";
import { WaitButtonSpan } from "../WaitButtonSpan";
import { WebappStepTwoSkeleton } from "../WebappStepTwoSkeleton";

interface props {
  setActiveIndex: (activeIndex: number) => void;
}

export const MerchantWebappStepTwo = ({ setActiveIndex }: props) => {
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [qrLoading, setQrLoading] = useState(true);
  const [addressQrLoading, setAddressQrLoading] = useState(true);
  const { setShowAlert, setAlertMessage } = useContext(ShowAlertContext);
  const showAlert = (message: string) => {
    setAlertMessage(message);
    setShowAlert(true);
  };
  const { id } = useParams();
  const { stepOnePayment, sandboxRequest } = useRequest();
  const {
    price,
    coinsListMap,
    setMerchantsSession,
    merchantsSession,
    selectedCoin,
    selectedNetwork,
    currency,
  } = useContext(MerchantsContext);

  useEffect(() => {
    setQrLoading(true);
    setAddressQrLoading(true);
  }, [merchantsSession?.wallet]);

  useEffect(() => {
    Number(merchantsSession?.step) === 3 && setActiveIndex(3);
    // eslint-disable-next-line
  }, [merchantsSession?.step]);

  useEffect(() => {
    if (
      merchantsSession &&
      selectedCoin &&
      selectedNetwork &&
      (selectedCoin !== merchantsSession.coin ||
        selectedNetwork !== merchantsSession.network)
    ) {
      setFetching(true);
      stepOnePayment(
        id as string,
        "merchant",
        "",
        selectedCoin,
        selectedNetwork,
        "",
        ""
      )
        .then((res) => {
          if (res.status === 200) {
            setMerchantsSession(res.session);
          } else {
            FailedModalFire(alertsMapStepOneDonate[res.errorNum.toString()]);
          }
        })
        .catch((error) => errorHandler(error as Error))
        .finally(() => setFetching(false));
    }
    // eslint-disable-next-line
  }, [selectedCoin, selectedNetwork]);

  const sandboxRequestFn = (status: number) => {
    setBtnLoading(true);
    sandboxRequest(id as string, status).then((res) => {
      if (res.status === 200 && res.result === true) {
        setActiveIndex(4);
      } else {
        FailedModalFire(alertsMapSandbox[res.errorNum.toString()]);
      }
      setBtnLoading(false);
    });
  };

  return (
    <>
      {fetching ? (
        <WebappStepTwoSkeleton />
      ) : (
        merchantsSession && (
          <div className="mt-15 d-flex justify-content-center align-items-center flex-column">
            <p className="text-center fs-ios-22 fw-bolder mb-4">
              Send {coinsListMap[merchantsSession?.coin]?.name}
            </p>
            <p className="fs-ios-17 text-center">
              Send only {coinsListMap[merchantsSession?.coin]?.name} (
              {coinsListMap[merchantsSession?.coin]?.symbol}) to this address.
              <br />
              Sending other coins or less amount may result
              <br /> in permanent loss.
            </p>

            {merchantsSession.sandbox ? (
              <div>
                <button
                  type="button"
                  disabled={btnLoading}
                  onClick={() => sandboxRequestFn(1)}
                  className="btn btn-success mt-5 mb-3 w-100"
                >
                  {btnLoading ? <WaitButtonSpan /> : "Success payment"}
                </button>
              </div>
            ) : (
              <>
                <div className="text-center m-3 px-3">
                  <div className="p-2 d-inline-block my-2">
                    {qrLoading && (
                      <div className="h-150px w-150px bg-secondary rounded shine"></div>
                    )}
                    {merchantsSession?.wallet && (
                      <img
                        src={`http://api.qrserver.com/v1/create-qr-code/?data=${
                          networksNameMap[merchantsSession.network]
                        }${merchantsSession?.wallet}${
                          networkschainIdMap[merchantsSession.network]
                        }${networksAmountNameMap[merchantsSession.network]}${
                          merchantsSession.network === ("Ton" || "Polygon")
                            ? Math.floor(
                                Number(merchantsSession.amount) *
                                  networksAmountMap[merchantsSession.network]
                              )
                            : Number(merchantsSession.amount) *
                              networksAmountMap[merchantsSession.network]
                        }&size=150x150`}
                        alt="QR code"
                        className={`${qrLoading && "d-none"}`}
                        style={{ width: "150px" }}
                        onLoad={() => {
                          setQrLoading(false);
                        }}
                      />
                    )}
                  </div>
                  <div className="p-3">
                    <p className="m-0 px-5 mw-300px">
                      If you pay this invoice using{" "}
                      <a href="https://t.me/OxaPayWalletBot" target={"blank"}>
                        <span className="fw-bold text-primary">
                          OxaPay Telegram Wallet
                        </span>
                      </a>{" "}
                      there are no network fee incurred.
                    </p>
                  </div>
                </div>

                <TextCopyWidget
                  wrap={true}
                  showAlert={() => showAlert("Address copied to clipoboard")}
                  copyIcon={false}
                  text={merchantsSession?.wallet}
                  className="fs-ios-17 address-style mb-3 mt-7 fm-jetbrain cursor-pointer"
                />
                <p className="text-ios-light fs-ios-13 d-flex justify-content-center">
                  <span className="me-2">
                    Send{" "}
                    <span className="text-primary fw-bold">
                      {coinsListMap[merchantsSession?.coin]?.name}
                    </span>{" "}
                    {!coinsListMap[merchantsSession.coin]?.networkList[
                      merchantsSession.network
                    ]?.isMain && (
                      <span className="badge badge-light-primary text-primary">
                        {merchantsSession.network}
                      </span>
                    )}{" "}
                    to this address
                  </span>
                  <OverlayTrigger
                    placement="top-end"
                    overlay={
                      <Tooltip>
                        {addressQrLoading && (
                          <div className="h-150px w-150px bg-secondary rounded m-auto shine my-1"></div>
                        )}
                        <img
                          className={`mx-auto d-block my-1 mx-0 ${
                            addressQrLoading && "d-none"
                          }`}
                          src={`http://api.qrserver.com/v1/create-qr-code/?data=${merchantsSession?.wallet}&size=150x150`}
                          alt="link-qrcode"
                          onLoad={() => {
                            setAddressQrLoading(false);
                          }}
                        />
                      </Tooltip>
                    }
                  >
                    <i className="cursor-pointer fa-solid fa-qrcode fs-3 text-ios-light"></i>
                  </OverlayTrigger>
                </p>
              </>
            )}
            <TextCopyWidget
              showAlert={() => showAlert("Amount copied to clipoboard")}
              copyIcon={false}
              text={formatAmount(
                parseFloat(merchantsSession.amount),
                coinsListMap[merchantsSession.coin]?.displayPrecision
              )}
              addonText={`${merchantsSession.coin} (${
                currency
                  ? price + " " + currency
                  : "$" + Number(price).toLocaleString()
              })`}
              className="fs-ios-17 fw-semibold mb-1 mt-7 fm-jetbrain cursor-pointer"
            />
            <p className="text-ios-light fs-ios-13">Amount to send</p>
            <div className="p-10">
              <div className="d-flex align-items-center">
                <div className="circles mt-1 me-2">
                  <div className="first circle"></div>
                </div>
                <p className="fs-6 fw-semibold text-dark mb-0">
                  The rate will be updated in{" "}
                  <span className="text-primary fw-bold">
                    <TimeCounter endtime={merchantsSession.expireAt} />
                  </span>
                </p>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};
