/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { App } from "../App";
import { Donation } from "../pages/Donation";
import { Paymentlink } from "../pages/Paymentlink";
import { Merchant } from "../pages/Merchant";
import { MerchantMiddleware } from "../pages/MerchantMiddleware";
import { Home } from "../pages/core/Home";
import { Coin } from "../pages/core/Coin";
import { AllCoins } from "../pages/core/AllCoins";
import { PaymentlinkPage } from "../pages/core/PaymentlinkPage";
import { DonationPage } from "../pages/core/DonationPage";
import { ReferralsPlan } from "../pages/core/ReferralsPlan";
import { Wallet } from "../pages/core/Wallet";
import { Pricing } from "../pages/core/Pricing";
import { PayoutAPI } from "../pages/core/PayoutAPI";
import { MerchantAPI } from "../pages/core/MerchantAPI";
import { LandingLayout } from "../pages/core/LandingLayout";
import { Swap } from "../pages/core/Swap";
import { MerchantCryptoInvoice } from "../pages/core/MerchantCryptoInvoice";
import { MerchantCryptoWhitelabel } from "../pages/core/MerchantCryptoWhitelabel";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route element={<LandingLayout />}>
            <Route index element={<Home />} />
            <Route path="/*" element={<Home />} />
            <Route path="currencies/:coinName" element={<Coin />} />
            {/* <Route path="telegram-channel-gateway" element={<CHGPage />} /> */}
            {/* <Route path="plugins-list" element={<AllPlugins />} /> */}
            <Route path="currencies" element={<AllCoins />} />
            <Route path="payment-link" element={<PaymentlinkPage />} />
            <Route path="donations" element={<DonationPage />} />
            <Route path="referrals-program" element={<ReferralsPlan />} />
            <Route path="wallet" element={<Wallet />} />
            <Route path="pricing" element={<Pricing />} />
            {/* <Route path="plugins-list/:pluginName" element={<Plugin />} /> */}
            <Route path="payout-api" element={<PayoutAPI />} />
            <Route path="merchant-api" element={<MerchantAPI />} />
            <Route
              path="merchant-crypto-invoice"
              element={<MerchantCryptoInvoice />}
            />
            <Route
              path="merchant-whitelabel"
              element={<MerchantCryptoWhitelabel />}
            />
            <Route path="swap" element={<Swap />} />
          </Route>
          <Route path="donate/:id" element={<Donation />} />
          <Route path="donate/:id/:sessionId/*" element={<Donation />} />
          {/* <Route path="webapp/:userId/:planId/*" element={<ChannelGateway />} /> */}
          <Route path="pay/:id" element={<Paymentlink />} />
          <Route path="pay/:id/:sessionId/*" element={<Paymentlink />} />
          <Route path="mpay/:id/*" element={<Merchant />} />
          <Route path="mpay" element={<MerchantMiddleware />} />
          <Route path="error/*" element={<ErrorsPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
