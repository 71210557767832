import React from "react";
import { Link, useLocation } from "react-router-dom";
import { toAbsoluteUrl } from "../../_oxapay/helpers";
import { IconHumberger } from "../staticIcons/IconHumberger";

export const NavBar = () => {
  const location = useLocation();
  const pathName = location.pathname;

  const isActive = (path: string) => {
    return pathName.startsWith(path) || (!pathName && path === "/home");
  };
  return (
    <div className="py-1 py-md-1">
      <div
        className="landing-header"
        data-kt-sticky="true"
        data-kt-sticky-name="landing-header"
        data-kt-sticky-offset="{default: '200px', lg: '300px'}"
      >
        <div className="container-fluid" style={{ maxWidth: "1550px" }}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center flex-equal">
              <button
                className="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none"
                id="kt_landing_menu_toggle"
                aria-label="Toggle Menu"
              >
                <span className="svg-icon svg-icon-2hx svg-icon-dark">
                  <IconHumberger />
                </span>
              </button>

              <a href="https://oxapay.com">
                <img
                  src={toAbsoluteUrl(`/media/logos/demo3.svg`)}
                  alt="OxaPay logo"
                  height="15px"
                  className="h-25px h-lg-30px"
                />
              </a>
            </div>

            <div className="d-lg-block" id="kt_header_nav_wrapper">
              <div
                className="d-lg-block p-5 p-lg-0"
                data-kt-drawer="true"
                data-kt-drawer-name="landing-menu"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="200px"
                data-kt-drawer-direction="start"
                data-kt-drawer-toggle="#kt_landing_menu_toggle"
                data-kt-swapper="true"
                data-kt-swapper-mode="prepend"
                data-kt-swapper-parent="{default: '#kt_header_nav_wrapper', lg: '#kt_header_nav_wrapper'}"
              >
                <div
                  id="kt_landing_menu"
                  className="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-dark-500 menu-state-title-primary nav nav-flush fs-5 fw-semibold"
                >
                  <div className="menu-item">
                    <Link
                      className={`menu-link nav-link py-3 px-4 px-xxl-6 fs-3 ${
                        isActive("/home") || pathName === "/"
                          ? "text-dark"
                          : "text-gray-600"
                      } text-hover-dark`}
                      to="/home"
                    >
                      Home
                    </Link>
                  </div>
                  <div className="d-flex align-items-center flex-shrink-0">
                    <div className={"d-flex align-items-center ms-3"}>
                      <>
                        <p
                          className={`menu-link nav-link py-3 px-4 px-xxl-6 fs-3 text-hover-dark cursor-pointer m-0 ${
                            isActive("/wallet") ||
                            isActive("/payment-link") ||
                            isActive("/merchant-api") ||
                            isActive("/payout-api") ||
                            isActive("/donations") ||
                            isActive("/swap") ||
                            isActive("/telegram-channel-gateway")
                              ? "text-dark"
                              : "text-gray-600"
                          }`}
                          data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                          data-kt-menu-placement="bottom-start"
                        >
                          Services{" "}
                          <i className="fa-solid fa-chevron-down fs-3"></i>
                        </p>

                        {/* begin::Menu */}
                        <div
                          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base mw-450px"
                          data-kt-menu="true"
                        >
                          {/* begin::Menu item */}
                          <div className="menu-item px-3 my-0">
                            <div className="d-flex flex-column flex-md-row">
                              <div className="w-150px">
                                <p className="pt-5 pb-2 px-4 fs-6 fs-lg-4 text-gray-800 fw-bold">
                                  Personal
                                </p>
                                <Link
                                  className={`menu-item py-2 px-4 px-xxl-6 fs-4 text-hover-dark ${
                                    isActive("/wallet")
                                      ? "text-dark"
                                      : "text-gray-600"
                                  }`}
                                  to="wallet"
                                >
                                  Wallet
                                </Link>
                                <Link
                                  className={`menu-link nav-link py-2 px-4 px-xxl-6 fs-4 text-hover-dark ${
                                    isActive("/payment-link")
                                      ? "text-dark"
                                      : "text-gray-600"
                                  }`}
                                  to="payment-link"
                                >
                                  Payment link
                                </Link>
                                <Link
                                  className={`menu-link nav-link py-2 px-4 px-xxl-6 fs-4 text-hover-dark ${
                                    isActive("/donations")
                                      ? "text-dark"
                                      : "text-gray-600"
                                  }`}
                                  to="donations"
                                >
                                  Donation link
                                </Link>
                                <Link
                                  className={`menu-link nav-link py-2 px-4 px-xxl-6 fs-4 text-hover-dark ${
                                    isActive("/swap")
                                      ? "text-dark"
                                      : "text-gray-600"
                                  }`}
                                  to="swap"
                                >
                                  Swap
                                </Link>
                              </div>
                              <div className="w-300px">
                                <p className="pt-5 pb-2 px-4 fs-6 fs-lg-4 text-gray-800 fw-bold">
                                  Business
                                </p>
                                <Link
                                  className={`menu-link nav-link py-2 px-4 px-xxl-6 fs-4 text-hover-dark ${
                                    isActive("/merchant-api")
                                      ? "text-dark"
                                      : "text-gray-600"
                                  }`}
                                  to="merchant-api"
                                >
                                  Merchant API
                                </Link>
                                <Link
                                  className={`menu-link nav-link py-2 px-4 px-xxl-6 fs-4 text-hover-dark ${
                                    isActive("/payout-api")
                                      ? "text-dark"
                                      : "text-gray-600"
                                  }`}
                                  to="payout-api"
                                >
                                  Payout API
                                </Link>

                                {/* <Link
                                  className={`menu-link nav-link py-2 px-4 px-xxl-6 fs-4 text-hover-dark ${
                                    isActive("/telegram-channel-gateway")
                                      ? "text-dark"
                                      : "text-gray-600"
                                  }`}
                                  to="telegram-channel-gateway"
                                >
                                  Telegram Channel Gateway
                                </Link> */}
                                <Link
                                  className={`menu-link nav-link py-2 px-4 px-xxl-6 fs-4 text-hover-dark ${
                                    isActive("/donations")
                                      ? "text-dark"
                                      : "text-gray-600"
                                  }`}
                                  to="donations"
                                >
                                  Donation link
                                </Link>
                              </div>
                            </div>
                          </div>
                          {/* end::Menu item */}
                        </div>
                        {/* end::Menu */}
                      </>
                    </div>
                  </div>

                  <div className="menu-item">
                    <Link
                      className={`menu-link nav-link py-3 px-4 px-xxl-6 fs-3 text-hover-dark ${
                        isActive("/currencies") ? "text-dark" : "text-gray-600"
                      }`}
                      to="currencies"
                    >
                      Supported Coins
                    </Link>
                  </div>

                  {/* <div className="menu-item">
                    <Link
                      className={`menu-link nav-link py-3 px-4 px-xxl-6 fs-3 text-hover-dark ${
                        isActive("/plugins-list")
                          ? "text-dark"
                          : "text-gray-600"
                      }`}
                      to="plugins-list"
                    >
                      Plugins
                    </Link>
                  </div> */}

                  <div className="menu-item">
                    <Link
                      className={`menu-link nav-link py-3 px-4 px-xxl-6 fs-3 text-hover-dark ${
                        isActive("/referrals-program")
                          ? "text-dark"
                          : "text-gray-600"
                      }`}
                      to="referrals-program"
                    >
                      Referrals Program
                    </Link>
                  </div>

                  <div className="menu-item">
                    <Link
                      className={`menu-link nav-link py-3 px-4 px-xxl-6 fs-3 text-hover-dark ${
                        isActive("/pricing") ? "text-dark" : "text-gray-600"
                      }`}
                      to="pricing"
                    >
                      Pricing
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-equal text-end ms-1">
              <a
                href="https://app.oxapay.com/signup"
                className="btn btn-dark fs-3 rounded-35px mx-2 px-10 d-none d-lg-inline-block"
              >
                Sign Up
              </a>
              <a
                href="https://app.oxapay.com/signin"
                className="text-dark fw-bold fs-3 py-3 mx-5 ps-7"
              >
                Sign In
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
