import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../_oxapay/helpers";

export const Footer = () => {
  return (
    <>
      <div className="p-5">
        <div className="vertical-gray-gradient rounded-35px p-10 w-100 overflow-hidden">
          <div className="container-fluid max-width-1550">
            <div className="row pt-20 pb-10 justify-content-between">
              <div className="col-12 col-md-4 mb-10">
                <a href="https://oxapay.com">
                  <img
                    src={toAbsoluteUrl(`/media/logos/demo3.svg`)}
                    alt="OxaPay logo"
                    height="15px"
                    className="theme-light-show logo-default h-25px h-lg-30px mb-7"
                  />
                  <img
                    src={toAbsoluteUrl(`/media/logos/demo3-dark.svg`)}
                    alt="OxaPay logo"
                    height="15px"
                    className="theme-dark-show logo-default h-25px h-lg-30px mb-7"
                  />
                </a>
                <p className="fs-4 mb-10">
                  OxaPay is a cryptocurrency payment gateway that simplifies the
                  acceptance of crypto for webmasters, merchants, and makes
                  crypto useful for all.
                </p>
                <a
                  href="https://cryptojobslist.com/companies/oxapay"
                  target="_blank"
                  style={{
                    display: "inline-block",
                    border: "0.09em solid #453dff",
                    borderRadius: "3px",
                    color: "#453dff",
                    padding: "0.3em 0.2em 0.4em",
                    textAlign: "center",
                    width: "7.2em",
                  }}
                  rel="noopener"
                >
                  We are hiring!
                </a>
                {/* <div className="d-flex align-items-center">
                  <p className="my-0 fs-5 me-1">Write a review on</p>
                  <a
                    href="https://www.trustpilot.com/review/oxapay.com"
                    target={"_blank"}
                    rel={"noreferrer"}
                  >
                    <img
                      className="width-fit-content my-2 h-30px"
                      src={toAbsoluteUrl(
                        "/media/svg/brand-logos/trustpilot.svg"
                      )}
                      alt="trust pilot"
                    />
                  </a>
                </div> */}
              </div>
              <div className="col-12 col-md-8">
                <div className="row">
                  <div className="col-12 col-md-4 mb-10">
                    <h3 className="fs-1 fw-semibold mb-8">Services</h3>
                    <ul className="no-bullets footer-list p-0">
                      <li className="mb-4">
                        <Link
                          className="fs-4 text-gray-600 text-hover-dark"
                          to={"payment-link"}
                        >
                          Payment link
                        </Link>
                      </li>
                      <li className="mb-4">
                        <Link
                          className="fs-4 text-gray-600 text-hover-dark"
                          to={"donations"}
                        >
                          Donation link
                        </Link>
                      </li>
                      <li className="mb-4">
                        <Link
                          className="fs-4 text-gray-600 text-hover-dark"
                          to={"swap"}
                        >
                          Swap
                        </Link>
                      </li>
                      <li className="mb-4">
                        <Link
                          className="fs-4 text-gray-600 text-hover-dark"
                          to={"wallet"}
                        >
                          Wallet
                        </Link>
                      </li>
                      <li className="mb-4">
                        <Link
                          className="fs-4 text-gray-600 text-hover-dark"
                          to={"merchant-api"}
                        >
                          Merchant API
                        </Link>
                      </li>
                      <li className="mb-4">
                        <Link
                          className="fs-4 text-gray-600 text-hover-dark"
                          to={"payout-api"}
                        >
                          Payout API
                        </Link>
                      </li>
                      {/* <li className="mb-4">
                        <Link
                          className="fs-4 text-gray-600 text-hover-dark"
                          to={"telegram-channel-gateway"}
                        >
                          Telegram channel gateway
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                  <div className="col-12 col-md-4 mb-10">
                    <h3 className="fs-1 fw-semibold mb-8">OxaPay links</h3>
                    <ul className="no-bullets footer-list p-0">
                      <li className="mb-4">
                        <Link
                          className="fs-4 text-gray-600 text-hover-dark"
                          to={"currencies"}
                        >
                          Supported coins
                        </Link>
                      </li>
                      {/* <li className="mb-4">
                        <Link
                          className="fs-4 text-gray-600 text-hover-dark"
                          to={"plugins-list"}
                        >
                          Plugins
                        </Link>
                      </li> */}
                      <li className="mb-4">
                        <a
                          href="https://oxapay.tawk.help/"
                          className="fs-4 text-gray-600 text-hover-dark"
                          target={"_blank"}
                          rel={"noreferrer"}
                        >
                          Customer Support
                        </a>
                      </li>
                      <li className="mb-4">
                        <a
                          href="https://app.oxapay.com/signup"
                          className="fs-4 text-gray-600 text-hover-dark"
                        >
                          Sign Up
                        </a>
                      </li>
                      <li className="mb-4">
                        <a
                          href="https://app.oxapay.com/signin"
                          className="fs-4 text-gray-600 text-hover-dark"
                        >
                          Sign In
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-md-4">
                    <h3 className="fs-1 fw-semibold mb-8">More</h3>
                    <ul className="no-bullets footer-list p-0 mb-10">
                      <li className="mb-4">
                        <a
                          href="https://docs.oxapay.com"
                          className="fs-4 text-gray-600 text-hover-dark"
                          target={"_blank"}
                          rel={"noreferrer"}
                        >
                          Documentation
                        </a>
                      </li>
                      <li className="mb-4">
                        <a
                          href="https://oxapay.com/blog"
                          className="fs-4 text-gray-600 text-hover-dark"
                          target={"_blank"}
                          rel={"noreferrer"}
                        >
                          Blog
                        </a>
                      </li>
                    </ul>
                    <h3 className="fs-1 fw-semibold mb-8">Follow Us</h3>
                    <div className="d-flex flex-row">
                      <a
                        href="https://x.com/oxapay"
                        className="fs-4 text-gray-600 text-hover-dark"
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        <img
                          className="h-25px me-5"
                          src={toAbsoluteUrl(
                            "/media/svg/brand-logos/x-dark.svg"
                          )}
                          alt="X logo"
                        />
                      </a>
                      <a
                        href="https://t.me/oxapay"
                        className="fs-4 text-gray-600 text-hover-dark"
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        <img
                          className="h-30px me-5"
                          src={toAbsoluteUrl(
                            "/media/svg/brand-logos/telegram-dark.svg"
                          )}
                          alt="telegram logo"
                        />
                      </a>
                      <a
                        href="https://medium.com/@oxapay"
                        className="fs-4 text-gray-600 text-hover-dark"
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        <img
                          className="h-30px"
                          src={toAbsoluteUrl(
                            "/media/svg/brand-logos/medium-dark.svg"
                          )}
                          alt="medium logo"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="mb-8 opacity-10" />
          <div className="container-fluid max-width-1550">
            <div className="d-flex flex-column flex-sm-row justify-content-between">
              <div>
                <button
                  className="btn-link text-dark fs-4 "
                  data-bs-toggle="modal"
                  data-bs-target="#terms"
                >
                  Terms & Conditions
                </button>
              </div>
              <div>
                <p className="fs-4">
                  All rights reserved for OxaPay © 2022-
                  {new Date().getFullYear()}
                </p>
              </div>
              <div className="d-flex flex-row">
                <a
                  href="https://app.oxapay.com/signin"
                  className="text-dark fs-4 me-7"
                >
                  Sign In
                </a>
                <a
                  href="https://app.oxapay.com/signup"
                  className="text-dark fs-4"
                >
                  Sign Up
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
